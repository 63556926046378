import { getAppConfig } from '../utils/config';
import { graphQLInstance } from '../utils/api';
import { AxiosInstance } from 'axios';

export function fetcher<Data, QueryVariables>(query: string, variables: QueryVariables): any {
  return async (): Promise<Data> => {
    const { reactAppApiV2Endpoint } = getAppConfig();
    const { data } = await graphQLInstance.request({
      method: 'POST',
      url: reactAppApiV2Endpoint,
      headers: { 'x-locale': 'en-US' },
      data: JSON.stringify({ query, variables })
    });
    return data;
  };
}

export async function requestGraphQL<Data, QueryVariables = unknown>(
  query: string,
  variables?: QueryVariables,
  instance: AxiosInstance = graphQLInstance
): Promise<Data> {
  const { reactAppApiV2Endpoint } = getAppConfig();
  const { data } = await instance.request({
    method: 'POST',
    url: reactAppApiV2Endpoint,
    headers: { 'x-locale': 'en-US' },
    data: JSON.stringify({ query, variables: variables as QueryVariables })
  });
  return data;
}
