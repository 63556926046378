import createRoutes from 'next-routes';
import Routes from 'next-routes';

// @ts-ignore Types are broken
const routes: Routes = createRoutes();

export interface IRoute {
  name: string;
  url: string;
  path: string;
  title?: string;
}

export type TRouteKeys =
  | 'BLOG_ARTICLE_CATEGORIES_DETAILS'
  | 'BLOG_ARTICLE_CATEGORIES_LIST'
  | 'BLOG_ARTICLE_CATEGORIES_NEW'
  | 'BLOG_ARTICLES_DETAILS'
  | 'BLOG_ARTICLES_LIST'
  | 'BLOG_ARTICLES_NEW'
  | 'BLOG_DASHBOARD'
  | 'CIRCUITS_CIRCUIT_DETAILS'
  | 'DASHBOARD'
  | 'EXERCISE_ALTERNATIVES_GROUPS_LIST'
  | 'EXERCISE_ALTERNATIVES_GROUPS_NEW'
  | 'EXERCISE_ALTERNATIVES_GROUPS_DETAILS'
  | 'EXERCISES_EXERCISE_DETAILS'
  | 'EXERCISES_EXERCISE_NEW'
  | 'EXERCISES_LIST'
  | 'EQUIPMENTS_LIST'
  | 'EQUIPMENTS_EQUIPMENT_DETAILS'
  | 'EQUIPMENTS_EQUIPMENT_NEW'
  | 'INFLUENCERS_INFLUENCER_DETAILS'
  | 'INFLUENCERS_INFLUENCER_LIST'
  | 'LOGIN'
  | 'LOGOUT'
  | 'MEDIA_LIBRARY'
  | 'PROGRAMS_DASHBOARD'
  | 'PROGRAMS_LIST'
  | 'PROGRAMS_PROGRAM_DETAILS'
  | 'PROGRAMS_PROGRAM_NEW'
  | 'PROGRAMS_WEEKS_LIST'
  | 'PROGRAMS_WEEKS_WEEK_DETAILS'
  | 'RESET_PASSWORD'
  | 'RESTDAYS_LIST'
  | 'RESTDAYS_RESTDAY_DETAILS'
  | 'RESTDAYS_RESTDAY_NEW'
  | 'RECIPES_DASHBOARD'
  | 'RECIPES_LIST'
  | 'RECIPE_LISTS'
  | 'RECIPES_RECIPE_NEW'
  | 'RECIPES_RECIPE_DETAILS'
  | 'RECIPE_INGREDIENTS_LIST'
  | 'RECIPE_INGREDIENTS_DETAILS'
  | 'INGREDIENT_CATEGORIES_LIST'
  | 'INGREDIENT_CATEGORIES_DETAIL'
  | 'SETTINGS'
  | 'TUTORIALS_TUTORIAL_DETAILS'
  | 'TUTORIALS_TUTORIAL_LIST'
  | 'USERS_USER_LIST'
  | 'USERS_USER_DETAILS'
  | 'USERS_USER_EVENTS'
  | 'WORKOUTLIST_DETAILS'
  | 'WORKOUTS_LIST'
  | 'WORKOUTS_WORKOUT_CIRCUITS'
  | 'WORKOUTS_WORKOUT_DETAILS'
  | 'WORKOUTS_WORKOUT_NEW';

export const ROUTES: Record<TRouteKeys, IRoute> = {
  BLOG_ARTICLE_CATEGORIES_DETAILS: {
    name: 'blog_articles_categories_details',
    url: '/blog/articles-categories/:id',
    path: 'blog/articleCategoryDetails',
    title: 'Edit article category'
  },
  BLOG_ARTICLE_CATEGORIES_LIST: {
    name: 'blog_articles_categories_list',
    url: '/blog/articles-categories/list',
    path: 'blog/articleCategoriesList',
    title: 'Article categories list'
  },
  BLOG_ARTICLE_CATEGORIES_NEW: {
    name: 'blog_articles_categories_new',
    url: '/blog/articles-categories/new',
    path: 'blog/articleCategoryDetails',
    title: 'Add a new article category'
  },
  BLOG_ARTICLES_LIST: {
    name: 'blog_articles_list',
    url: '/blog/articles/list',
    path: 'blog/articlesList',
    title: 'Articles list'
  },
  BLOG_ARTICLES_DETAILS: {
    name: 'blog_articles_details',
    url: '/blog/articles/:id',
    path: 'blog/articleDetails',
    title: 'Edit article'
  },
  BLOG_ARTICLES_NEW: {
    name: 'blog_articles_new',
    url: '/blog/articles/new',
    path: 'blog/articleDetails',
    title: 'Add a new article'
  },
  BLOG_DASHBOARD: { name: 'blog_dashboard', url: '/blog', path: 'blog', title: 'Blog' },
  CIRCUITS_CIRCUIT_DETAILS: {
    name: 'circuits_details',
    url: '/circuits/details',
    path: 'circuits/circuitDetails',
    title: 'Exercises of your workout'
  },
  DASHBOARD: { name: 'dashboard', url: '/dashboard', path: 'dashboard', title: 'Dashboard' },
  EXERCISE_ALTERNATIVES_GROUPS_LIST: {
    name: 'exercise_alternatives_groups_list',
    url: '/exercise-alternatives-groups/list',
    path: 'exerciseAlternativesGroups/exerciseAlternativesGroupsList',
    title: 'Exercise Alternatives Groups List'
  },
  EXERCISE_ALTERNATIVES_GROUPS_NEW: {
    name: 'exercise_alternatives_groups_new',
    url: '/exercise-alternatives-groups/new',
    path: 'exerciseAlternativesGroups/exerciseAlternativesGroupDetails',
    title: 'New Exercise Alternatives Group'
  },
  EXERCISE_ALTERNATIVES_GROUPS_DETAILS: {
    name: 'exercise_alternatives_groups_details',
    url: '/exercise-alternatives-groups/:id',
    path: 'exerciseAlternativesGroups/exerciseAlternativesGroupDetails',
    title: 'Edit Exercise Alternatives Group'
  },
  EXERCISES_EXERCISE_DETAILS: {
    name: 'exercises_details',
    url: '/exercises/details',
    path: 'exercises/exerciseDetails',
    title: 'Edit an exercise'
  },
  EXERCISES_EXERCISE_NEW: {
    name: 'exercises_new',
    url: '/exercises/new',
    path: 'exercises/exerciseDetails',
    title: 'Create a new exercise'
  },
  EXERCISES_LIST: {
    name: 'exercises_list',
    url: '/exercises/list',
    path: 'exercises/exercisesList',
    title: 'Exercises list'
  },
  EQUIPMENTS_LIST: {
    name: 'equipments_list',
    url: '/equipments/list',
    path: 'equipments/equipmentsList',
    title: 'Equipments list'
  },
  EQUIPMENTS_EQUIPMENT_DETAILS: {
    name: 'equipments_details',
    url: '/equipments/details',
    path: 'equipments/equipmentDetails',
    title: 'Edit an equipment'
  },
  EQUIPMENTS_EQUIPMENT_NEW: {
    name: 'equipments_new',
    url: '/equipments/new',
    path: 'equipments/equipmentDetails',
    title: 'Add a new equipment'
  },
  INFLUENCERS_INFLUENCER_DETAILS: {
    name: 'influencers_details',
    url: '/influencers/details',
    path: 'influencers/influencerDetails',
    title: 'Edit influencer'
  },
  INFLUENCERS_INFLUENCER_LIST: {
    name: 'influencers_list',
    url: '/influencers/list',
    path: 'influencers/influencersList',
    title: 'Influencers list'
  },
  LOGIN: { name: 'login', url: '/', path: 'login' },
  LOGOUT: { name: 'logout', url: '/logout', path: 'logout' }, // Dashboard
  MEDIA_LIBRARY: {
    name: 'media_library',
    url: '/media-library',
    path: 'mediaLibrary',
    title: 'Media library'
  },
  PROGRAMS_DASHBOARD: {
    name: 'programs_dashboard',
    url: '/programs',
    path: 'programs',
    title: 'Programs, workouts & exercises'
  },
  PROGRAMS_LIST: {
    name: 'programs_list',
    url: '/programs/list',
    path: 'programs/programsList',
    title: 'Programs list'
  },
  PROGRAMS_PROGRAM_DETAILS: {
    name: 'programs_details',
    url: '/programs/details',
    path: 'programs/programDetails',
    title: 'Edit a program'
  },
  PROGRAMS_PROGRAM_NEW: {
    name: 'programs_new',
    url: '/programs/new',
    path: 'programs/programDetails',
    title: 'Add a new program'
  },
  PROGRAMS_WEEKS_LIST: {
    name: 'programs_weeks_list',
    url: '/programs/weeks/list',
    path: 'programs/weeks/weeksList',
    title: 'Weeks of your program'
  },
  PROGRAMS_WEEKS_WEEK_DETAILS: {
    name: 'programs_week_details',
    url: '/programs/weeks/details',
    path: 'programs/weeks/weekDetails',
    title: 'Workouts of the week'
  },
  RECIPE_INGREDIENTS_DETAILS: {
    name: 'recipe_ingredients_details',
    url: '/recipe/ingredients/ingredientDetails',
    path: 'recipes/ingredients/ingredientDetails',
    title: 'Edit an ingredient'
  },
  RECIPE_INGREDIENTS_LIST: {
    name: 'recipe_ingredients_list',
    url: '/recipe/ingredients/ingredientsList',
    path: 'recipes/ingredients/ingredientsList',
    title: 'Ingredients lists'
  },
  RECIPE_LISTS: {
    name: 'recipe_lists',
    url: '/recipe-lists',
    path: 'recipes/recipeLists',
    title: 'Recipe lists'
  },
  RECIPES_DASHBOARD: {
    name: 'recipes_dashboard',
    url: '/recipes',
    path: 'recipes',
    title: 'Recipes'
  },
  RECIPES_LIST: {
    name: 'recipes_list',
    url: '/recipes/list',
    path: 'recipes/recipesList',
    title: 'Recipes list'
  },
  RECIPES_RECIPE_DETAILS: {
    name: 'recipes_recipe_details',
    url: '/recipes/:id',
    path: 'recipes/recipeDetails',
    title: 'Edit a recipe'
  },
  RECIPES_RECIPE_NEW: {
    name: 'recipes_recipe_new',
    url: '/recipes/new',
    path: 'recipes/recipeDetails',
    title: 'Add a new recipe'
  },
  INGREDIENT_CATEGORIES_LIST: {
    name: 'ingredient_categories_list',
    url: '/recipe/ingredientCategories/list',
    path: 'recipes/ingredientCategories/ingredientCategoriesList',
    title: 'Ingredient categories list'
  },
  INGREDIENT_CATEGORIES_DETAIL: {
    name: 'ingredient_categories_detail',
    url: '/recipe/ingredientCategories/details',
    path: 'recipes/ingredientCategories/ingredientCategoriesDetails',
    title: 'Edit an ingredient category'
  },
  RESET_PASSWORD: { name: 'reset_password', url: '/reset_password/:token', path: 'resetPassword' },
  RESTDAYS_LIST: {
    name: 'restDays_list',
    url: '/restDays/list',
    path: 'restDays/restDaysList',
    title: 'Rest days list'
  },
  RESTDAYS_RESTDAY_DETAILS: {
    name: 'restDays_details',
    url: '/restDays/details',
    path: 'restDays/restDayDetails',
    title: 'Edit a rest day'
  },
  RESTDAYS_RESTDAY_NEW: {
    name: 'restDays_new',
    url: '/restDays/new',
    path: 'restDays/restDayDetails',
    title: 'Add a new rest day'
  },
  SETTINGS: { name: 'settings', url: '/settings', path: 'settings', title: 'Settings' },
  TUTORIALS_TUTORIAL_DETAILS: {
    name: 'tutorials_details',
    url: '/tutorials/details',
    path: 'tutorials/tutorialDetails',
    title: 'Edit tutorial'
  },
  TUTORIALS_TUTORIAL_LIST: {
    name: 'tutorials_list',
    url: '/tutorials/list',
    path: 'tutorials/tutorialsList',
    title: 'Tutorials list'
  },
  USERS_USER_LIST: {
    name: 'users_list',
    url: '/users/list',
    path: 'users/usersList',
    title: 'Users list'
  },
  USERS_USER_DETAILS: {
    name: 'users_details',
    url: '/users/:id',
    path: 'users/userDetails',
    title: 'Users details'
  },
  USERS_USER_EVENTS: {
    name: 'user_events',
    url: '/users/:id/events',
    path: 'users/userEvents',
    title: 'Users events'
  },
  WORKOUTLIST_DETAILS: {
    name: 'workoutlists_details',
    url: '/workoutLists/details',
    path: 'workoutLists/workoutListDetails',
    title: 'Edit a workout list'
  },
  WORKOUTS_LIST: {
    name: 'workouts_list',
    url: '/workouts/list',
    path: 'workouts/workoutsList',
    title: 'Workouts list'
  },
  WORKOUTS_WORKOUT_CIRCUITS: {
    name: 'workouts_circuits',
    url: '/workouts/circuits',
    path: 'workouts/workoutCircuits',
    title: 'Circuits of your workout'
  },
  WORKOUTS_WORKOUT_DETAILS: {
    name: 'workouts_details',
    url: '/workouts/details',
    path: 'workouts/workoutDetails',
    title: 'Edit a workout'
  },
  WORKOUTS_WORKOUT_NEW: {
    name: 'workouts_new',
    url: '/workouts/new',
    path: 'workouts/workoutDetails',
    title: 'Add a new workout'
  }
};

(Object.keys(ROUTES) as TRouteKeys[]).forEach((key: TRouteKeys) => {
  const { name, path, url } = ROUTES[key];
  routes.add({ name, page: path, pattern: url });
});

export function getActiveRoute(asPath: string): TRouteKeys | false {
  const { route } = (routes as any).match(asPath);
  const routeName = route && route.name;
  if (!routeName) {
    return false;
  }
  const filteredRoute = (Object.keys(ROUTES) as TRouteKeys[]).filter(
    (routeKey: TRouteKeys) => ROUTES[routeKey].name === routeName
  );
  return filteredRoute.length ? filteredRoute[0] : false;
}

export default routes;
export const Link = routes.Link;
export const Router = routes.Router;
