import { AxiosInstance } from 'axios';
import getConfig from 'next/config';
import { parseCookies, setCookie } from 'nookies';
import { createAction, handleActions } from 'redux-actions';
import { IFailurePayload } from '../typings/boilerplate';
import { DEBUG_AUTH } from './api';
import { NextPageContext } from 'next';
import { requestGraphQL } from '../api';
import {
  LoginWithEmailDocument,
  LoginWithEmailMutation,
  LoginWithEmailMutationVariables,
  LoginWithRefreshTokenDocument,
  LoginWithRefreshTokenMutation,
  LoginWithRefreshTokenMutationVariables
} from '../src/typings/graphql';

const { publicRuntimeConfig } = getConfig();

export interface IAuthState {
  loading: boolean;
  connected: boolean;
  error: boolean;
  message: string;
}

const initialState: IAuthState = {
  loading: false,
  connected: false,
  error: false,
  message: null
};

export const CONSTANTS = {
  REQUEST: 'auth/REQUEST',
  SUCCESS: 'auth/SUCCESS',
  FAILURE: 'auth/FAILURE',
  LOGOUT: 'auth/LOGOUT'
};

export const request = createAction(CONSTANTS.REQUEST);
export const failure = createAction(CONSTANTS.FAILURE, (payload: IFailurePayload) => payload);
export const success = createAction(CONSTANTS.SUCCESS);
export const logout = createAction(CONSTANTS.LOGOUT);

export interface ILoginPayload {
  email?: string;
  password?: string;
  refreshToken?: string;
}

export const MESSAGES = {
  badCredentials: 'Invalid credentials. Please try again.',
  default: 'Cannot login. Please try again.'
};

export const login: any = (payload: ILoginPayload) => {
  return async (
    dispatch: (action: any) => void,
    _: any,
    { ctx }: { apiAuth: AxiosInstance; ctx: NextPageContext; apiV2Auth: AxiosInstance }
  ) => {
    dispatch(request());

    async function logUser({ email, password, refreshToken }: ILoginPayload) {
      DEBUG_AUTH && console.info('Logging in user with', { email, password, refreshToken });
      try {
        let data;
        if (refreshToken) {
          const res = await requestGraphQL<
            LoginWithRefreshTokenMutation,
            LoginWithRefreshTokenMutationVariables
          >(LoginWithRefreshTokenDocument, { refreshToken });
          data = {
            accessToken: res.refreshToken.accessToken,
            refreshToken: res.refreshToken.refreshToken
          };
        } else {
          const res = await requestGraphQL<LoginWithEmailMutation, LoginWithEmailMutationVariables>(
            LoginWithEmailDocument,
            {
              email,
              password
            }
          );
          data = { accessToken: res.login.accessToken, refreshToken: res.login.refreshToken };
        }
        loginSuccess(data.accessToken, data.refreshToken, email);
        return Promise.resolve(data.accessToken);
      } catch (error) {
        const { response } = error;
        // DEBUG_AUTH && console.info('Login error', response);
        let message;
        const status = response && response.status;
        switch (status) {
          case 401:
            message = MESSAGES.badCredentials;
            break;
          default:
            message = MESSAGES.default;
        }
        dispatch(failure({ statusCode: response && response.status, message }));
        return Promise.reject(error);
      }
    }

    function loginSuccess(accessToken: string, refreshToken: string, userEmail: string) {
      DEBUG_AUTH && console.info('Login success', { accessToken, refreshToken, userEmail });
      setCookie(ctx || null, publicRuntimeConfig.reactAppAccessTokenPath, accessToken, {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      });
      setCookie(ctx || null, publicRuntimeConfig.reactAppARefreshTokenPath, refreshToken, {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      });
      setCookie(ctx || null, publicRuntimeConfig.reactAppEmailPath, userEmail, {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      });
      dispatch(success());
    }

    if (!payload) {
      // Refresh authentication with refreshToken
      const refreshToken = parseCookies(ctx || null).refresh_token;
      const email = parseCookies(ctx || null).email;

      if (!refreshToken || !email) {
        throw new Error(
          `Both email and refreshToken are required in cookies in order to login without payload.
            Got : ${JSON.stringify({ refreshToken, email })}
          `
        );
      }
      DEBUG_AUTH &&
        console.info(
          `No paylaod found, refreshing with : ${JSON.stringify({ email, refreshToken })}`
        );
      return logUser({ email, refreshToken });
    } else {
      return logUser(payload);
    }
  };
};

type AllowedPayloads = IFailurePayload | undefined;
const authReducer = handleActions<IAuthState, AllowedPayloads>(
  {
    [CONSTANTS.REQUEST]: (state: IAuthState) => ({
      ...state,
      loading: true,
      error: false,
      message: null
    }),
    [CONSTANTS.SUCCESS]: (state: IAuthState) => ({
      ...state,
      loading: false,
      connected: true,
      error: false,
      message: null
    }),
    [CONSTANTS.FAILURE]: (state: IAuthState, { payload }: { payload: IFailurePayload }) => ({
      ...state,
      loading: false,
      error: true,
      message: payload.message
    })
  },
  initialState
);

export default authReducer;
