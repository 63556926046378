import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { parseCookies } from 'nookies';
import { login, logout } from '../store/auth';
import GraphQLErrorResponse from '../graphql/GraphQLError';
import { Store } from 'redux';

export const graphQLInstance = axios.create();

interface AxiosInterceptor {
  onFulfilled: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>;
  onRejected: (error: AxiosError) => any;
}

let store: Store;
let isRefreshing = false;
let subscribers: any = [];

function getRequestInterceptor({ apiV2 }: { apiV2: boolean }): AxiosInterceptor {
  return {
    onFulfilled: async (config: AxiosRequestConfig) => {
      if (!store) {
        return config;
      }

      config.headers['Content-Type'] = 'application/json';
      const accessToken = parseCookies(null).access_token;
      if (accessToken) {
        config.headers.authorization = `${apiV2 ? 'Bearer ' : ''}${accessToken}`;
      }

      return config;
    },
    onRejected: (error: AxiosError) => {
      return Promise.reject(error);
    }
  };
}

function getResponseInterceptor({ apiV2 }: { apiV2: boolean }): AxiosInterceptor {
  return {
    onFulfilled: async (response: any) => {
      if (!store) {
        return;
      }
      if (apiV2) {
        if (response?.data?.errors?.length) {
          if (
            response.data.errors.find(
              (graphQLError: any) => graphQLError.extensions?.response?.statusCode === 401
            )
          ) {
            const { config } = response;
            const originalRequest = config;
            return handle401({ originalRequest, config, apiV2 });
          }
          return Promise.reject(new GraphQLErrorResponse(response?.data?.errors));
        }
        return response.data;
      }
      return response;
    },
    onRejected: (error: AxiosError) => {
      const { config, response } = error;
      const originalRequest = config;
      if (response && response.status === 401) {
        return handle401({ originalRequest, config, apiV2 });
      } else {
        // unknown error
      }
      return Promise.reject(error);
    }
  };
}

function handle401({
  originalRequest,
  apiV2
}: {
  config: AxiosRequestConfig;
  originalRequest: AxiosRequestConfig;
  apiV2: boolean;
}): Promise<AxiosResponse> {
  if (!isRefreshing) {
    // If no previous request has already requested a token refresh
    isRefreshing = true;
    // Dispatch the login thunk with the current JWT in order to get a new one
    store
      .dispatch(login())
      .then((newAccessToken: string) => {
        isRefreshing = false;
        // Fire the callback of each subscriber, updating their respective authorization
        // headers and resuming the request w/ the new token
        onRefreshed(newAccessToken);
        subscribers = [];
      })
      .catch((refreshError: Error) => {
        isRefreshing = false;
        store.dispatch(logout());
        return Promise.reject(refreshError);
      });
  }
  // Return a promise that's going to halt the original request, and push it into
  // an array of subscribers that need an updated token
  const requestSubscribers = new Promise((resolve: (value?: any) => void) => {
    subscribeTokenRefresh((token: string) => {
      // Alter the orignal request headers w/ the refreshed token
      originalRequest.headers.authorization = `${apiV2 ? 'Bearer ' : ''}${token}`;
      // Fire it once again
      if (apiV2) {
        axios(originalRequest).then(response => {
          resolve(response.data);
        });
      } else {
        resolve(axios(originalRequest));
      }
    });
  });
  return requestSubscribers;
}

function onRefreshed(token: string): void {
  subscribers.map((cb: (token: string) => any) => cb(token));
}

function subscribeTokenRefresh(cb: (token: string) => any): void {
  subscribers.push(cb);
}

export function setStoreInstance(storeInstance: Store): void {
  store = storeInstance;
}

const graphQLRequestInterceptor = getRequestInterceptor({ apiV2: true });
graphQLInstance.interceptors.request.use(
  graphQLRequestInterceptor.onFulfilled,
  graphQLRequestInterceptor.onRejected
);

const graphQLResponseInterceptor = getResponseInterceptor({ apiV2: true });
graphQLInstance.interceptors.response.use(
  graphQLResponseInterceptor.onFulfilled as any,
  graphQLResponseInterceptor.onRejected
);
