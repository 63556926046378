import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import getConfig from 'next/config';

import { development, isServer } from '../utils/deviceDetection';
import { parseCookies } from 'nookies';
import { login, logout } from './auth';
import { Router, ROUTES } from '../server/routes';
import { Store } from 'redux';
import { NextPageContext } from 'next';

export const DEBUG_AUTH = true;

const { publicRuntimeConfig } = getConfig();

let httpsAgent;
if (isServer && development) {
  const https = require('https');
  // Bypass TLS checking because of our self-signed certificate
  httpsAgent = new https.Agent({
    rejectUnauthorized: false
  });
}

const INSTANCE_PARAMETERS = {
  // Requests from the server itself aren't proxied
  baseURL: `${publicRuntimeConfig.reactAppApiEndpoint}${publicRuntimeConfig.reactAppApiPath}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Disable-Cache': 'true',
    'X-Bundle-Id': 'com.nuli.backoffice.legacy',
    'X-Locale': publicRuntimeConfig.defaultLocale
  },
  httpsAgent
};

// The first instance will be used to fetch all data, and thus have the following
// interceptors attached to it.
// The latter is only used to authenticate the user, preventing infinite-loops.
const instance = axios.create(INSTANCE_PARAMETERS);
export const authInstance = axios.create(INSTANCE_PARAMETERS);
export const apiV2AuthInstance = axios.create({
  ...INSTANCE_PARAMETERS,
  baseURL: publicRuntimeConfig.reactAppApiV2Endpoint
});

let subscribers: any[] = [];

export function configureInterceptors(store: Store, ctx: NextPageContext): void {
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const { config, response } = error;
      const originalRequest = config;

      if (response && response.status === 401) {
        DEBUG_AUTH && console.info('Got 401');
        DEBUG_AUTH && console.info('Not refreshing, try logging in again...');
        store
          .dispatch(login())
          .then((newAccessToken: string) => {
            DEBUG_AUTH && console.info('New access token', newAccessToken);
            // Dispatch the login thunk with the current JWT in order to get a new one
            // Fire the callback of each subscriber, updating their respective authorization
            // headers and resuming the request w/ the new token
            onRrefreshed(newAccessToken);
            subscribers = [];
          })
          .catch((refreshError: Error) => {
            // DEBUG_AUTH && console.info('Refresh authentication error', refreshError);
            DEBUG_AUTH && console.info('Refresh authentication error');
            store.dispatch(logout());
            if (ctx && ctx.res) {
              ctx.res.writeHead(302, { Location: '/' });
              ctx.res.end();
            } else {
              Router.replaceRoute(ROUTES.LOGOUT.name);
            }
            return Promise.reject(refreshError);
          });
        // Return a promise that's going to halt the original request, and push it into
        // an array of subscribers that need an updated token
        const requestSubscribers = new Promise((resolve: (value?: any) => void) => {
          subscribeTokenRefresh((token: string) => {
            // Alter the orignal request headers w/ the refreshed token
            originalRequest.headers.Authorization = token;
            // Fire it once again
            DEBUG_AUTH && console.info(`${originalRequest.url} refreshed w/ new token...`);
            resolve(axios(originalRequest));
          });
        });
        return requestSubscribers;
      }
      return Promise.reject(error);
    }
  );
}

function subscribeTokenRefresh(cb: (token: string) => any): void {
  subscribers.push(cb);
}

function onRrefreshed(token: string): void {
  subscribers.map((cb: (token: string) => any) => cb(token));
}

export interface IRequestOptions extends AxiosRequestConfig {
  secure?: boolean;
}

const REQUEST_DEFAULT_OPTIONS = {
  method: 'GET',
  secure: true
};

export type TAPIRequest = (options: IRequestOptions) => AxiosPromise<any>;

export function requestAPI(token?: string): (options: IRequestOptions) => AxiosPromise<any> {
  return (options: IRequestOptions) => {
    if (!token) {
      token = parseCookies(null).access_token;
    }
    options = { ...REQUEST_DEFAULT_OPTIONS, ...options };
    // Pass JWT token only when needed
    options.headers = options.headers || {};
    if (token) {
      options.headers.Authorization = token;
    }
    return instance.request(options);
  };
}

export default instance;
