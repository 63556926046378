// @ts-nocheck
 /* tslint:disable */ 
 import { fetcher } from '../../api';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AffectedRowsType = {
  __typename?: 'AffectedRowsType';
  affectedRows: Scalars['Float'];
};

export type AlternativeExerciseType = {
  __typename?: 'AlternativeExerciseType';
  circuitExerciseId: Scalars['String'];
  exercise: Exercise;
};

export type ApplePaymentProcessedType = {
  __typename?: 'ApplePaymentProcessedType';
  isCanceled?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
};

/** iOS receipt payload required for validation from client-side (initial purchase or restoration) */
export type AppleReceiptPayloadInput = {
  signedPayload: Scalars['String'];
};

/** Blog articles */
export type Article = {
  __typename?: 'Article';
  articleGroups?: Maybe<Array<ArticleArticleGroups>>;
  author: UserType;
  categories?: Maybe<Array<ArticleCategories>>;
  createdAt: Scalars['DateTime'];
  focused: Scalars['Boolean'];
  fullScreenImage?: Maybe<MediaType>;
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  publicationStatus: PublicationStatus;
  slug?: Maybe<Scalars['String']>;
  translations: ArticleI18N;
  updatedAt: Scalars['DateTime'];
  videos?: Maybe<Array<ArticleVideos>>;
};

/** Article Group articles association */
export type ArticleArticleGroups = {
  __typename?: 'ArticleArticleGroups';
  articleGroup: ArticleGroup;
  articleGroupId: Scalars['String'];
  articleId: Scalars['String'];
  id: Scalars['ID'];
};

export type ArticleCategories = {
  __typename?: 'ArticleCategories';
  category: ArticleCategory;
  id: Scalars['ID'];
};

/** Multiple article category assigned to articles */
export type ArticleCategory = {
  __typename?: 'ArticleCategory';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  translations: ArticleCategoryI18N;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter articleCategories */
export type ArticleCategoryFiltersType = {
  /** Filter muscle group by name */
  title?: InputMaybe<Scalars['String']>;
};

/** Translated fields of articleCategories */
export type ArticleCategoryI18N = {
  __typename?: 'ArticleCategoryI18N';
  title: Scalars['String'];
};

/** Used to order articleCategories */
export type ArticleCategoryOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ArticleCategoryOrderProperties>;
};

/** ArticleCategory properties that can be ordered */
export enum ArticleCategoryOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Article favorite */
export type ArticleFavorite = {
  __typename?: 'ArticleFavorite';
  article?: Maybe<Article>;
  articleId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Used to order article favorites */
export type ArticleFavoriteOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ArticleFavoriteOrderProperties>;
};

/** Article Favorite properties that can be ordered */
export enum ArticleFavoriteOrderProperties {
  CreatedAt = 'createdAt'
}

/** Used to filter articles */
export type ArticleFiltersType = {
  /** Filter articles by articleGroups */
  articleGroupsIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter articles by author */
  authorId?: InputMaybe<Scalars['String']>;
  /** Filter articles by categories */
  categoriesIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter focused articles */
  focused?: InputMaybe<Scalars['String']>;
  /** Filter deleted articles */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Filter articles by publication status */
  publicationStatus?: InputMaybe<PublicationStatus>;
  /** Filter articles by name */
  title?: InputMaybe<Scalars['String']>;
};

/** Article Group to group article by topic */
export type ArticleGroup = {
  __typename?: 'ArticleGroup';
  articleGroupArticles?: Maybe<Array<ArticleGroupArticles>>;
  color1: Scalars['String'];
  color2: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  translations: ArticleGroupI18n;
};

/** Article Group articles association */
export type ArticleGroupArticles = {
  __typename?: 'ArticleGroupArticles';
  article: Article;
  articleGroup: ArticleGroup;
  articleGroupId: Scalars['String'];
  articleId: Scalars['String'];
  id: Scalars['ID'];
};

/** Used to filter articleGroups */
export type ArticleGroupFiltersType = {
  /** Filter article groups by title */
  title?: InputMaybe<Scalars['String']>;
};

/** Translated fields of articleGroup */
export type ArticleGroupI18n = {
  __typename?: 'ArticleGroupI18n';
  description: Scalars['String'];
  title: Scalars['String'];
};

/** Translated fields of articles */
export type ArticleI18N = {
  __typename?: 'ArticleI18N';
  content: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Used to order articles */
export type ArticleOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ArticleOrderProperties>;
};

/** Article properties that can be ordered */
export enum ArticleOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Videos of article */
export type ArticleVideos = {
  __typename?: 'ArticleVideos';
  id: Scalars['ID'];
  video?: Maybe<MediaType>;
};

export type AssignCoachesToUserDto = {
  coachIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type AssignCoachesToUserResponseType = {
  __typename?: 'AssignCoachesToUserResponseType';
  coachId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AssignCoachingProgramDto = {
  programId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Bulk update program groups */
export type BulkUpdateProgramTagInput = {
  id: Scalars['String'];
  internalName?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  slug?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateProgramTagTranslationsInput>>;
};

/** Used to update progress picture date */
export type ChangeProgressPictureDateType = {
  date: Scalars['DateTime'];
  progressPictureId: Scalars['String'];
};

export type Circuit = {
  __typename?: 'Circuit';
  circuitExercises?: Maybe<Array<CircuitExercise>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

/** Circuit and exercise associations */
export type CircuitExercise = {
  __typename?: 'CircuitExercise';
  enableExerciseAlternativesGroups: Scalars['Boolean'];
  exercise: Exercise;
  id: Scalars['ID'];
  personalizeAutomatically: Scalars['Boolean'];
  position: Scalars['Float'];
  restAfterLastSet?: Maybe<Scalars['Float']>;
  restBetweenSets?: Maybe<Scalars['Float']>;
  sets?: Maybe<Array<CircuitExerciseSets>>;
  type: CircuitExerciseType;
};

/** Circuit exercise associated sets */
export type CircuitExerciseSets = {
  __typename?: 'CircuitExerciseSets';
  id: Scalars['ID'];
  position: Scalars['Float'];
  reps?: Maybe<Scalars['Float']>;
  toFailure: Scalars['Boolean'];
};

/** The different types of circuit exercise */
export enum CircuitExerciseType {
  Reps = 'REPS',
  Time = 'TIME'
}

export type ClaimAccountDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CoachType = {
  __typename?: 'CoachType';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  picture?: Maybe<MediaType>;
};

export type CoachingClientTagType = {
  __typename?: 'CoachingClientTagType';
  color: Scalars['String'];
  label: Scalars['String'];
  slug: Scalars['String'];
};

export type CoachingClientType = {
  __typename?: 'CoachingClientType';
  activeCoachingProgram?: Maybe<Program>;
  activeCoachingProgramProgression?: Maybe<ProgramProgression>;
  birthDate?: Maybe<Scalars['DateTime']>;
  coaches: Array<CoachType>;
  coachingTags: Array<CoachingClientTagType>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Last 2 weeks of measurements history */
  lastMeasurements: Array<MeasurementHistory>;
  lastName?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<UserUpload>;
};

export type Collage = {
  __typename?: 'Collage';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: UserUploadStatuses;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

/** Used to order collages */
export type CollageOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<CollageOrderProperties>;
};

/** collage properties that can be ordered */
export enum CollageOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Possible connection methods */
export enum ConnectionMethods {
  Apple = 'APPLE',
  Claimable = 'CLAIMABLE',
  Email = 'EMAIL',
  Google = 'GOOGLE'
}

export type CreateArticleArticleGroupsDto = {
  articleGroupId: Scalars['String'];
};

export type CreateArticleCategoriesDto = {
  categoryId: Scalars['String'];
};

/** Create a new articleCategory */
export type CreateArticleCategoryInput = {
  color: Scalars['String'];
  slug: Scalars['String'];
  translations: Array<CreateArticleCategoryTranslationsInput>;
};

/** Create translations of a new articleCategory */
export type CreateArticleCategoryTranslationsInput = {
  locale: Scalars['String'];
  title: Scalars['String'];
};

export type CreateArticleDto = {
  articleGroups: Array<CreateArticleArticleGroupsDto>;
  categories?: InputMaybe<Array<CreateArticleCategoriesDto>>;
  focused?: InputMaybe<Scalars['Boolean']>;
  fullscreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  translations: Array<CreateArticleTranslationsDto>;
};

export type CreateArticleGroupArticlesDto = {
  articleId: Scalars['String'];
};

/** Create a new articleGroup */
export type CreateArticleGroupInput = {
  articleGroupArticles?: InputMaybe<Array<CreateArticleGroupArticlesDto>>;
  color1: Scalars['String'];
  color2: Scalars['String'];
  imageId: Scalars['String'];
  translations: Array<CreateArticleGroupTranslationsInput>;
};

/** Create translations of a new articleGroup */
export type CreateArticleGroupTranslationsInput = {
  description: Scalars['String'];
  locale: Scalars['String'];
  title: Scalars['String'];
};

export type CreateArticleTranslationsDto = {
  content: Scalars['String'];
  locale: Scalars['String'];
  summary?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Create circuit exercise */
export type CreateCircuitExerciseDto = {
  enableExerciseAlternativesGroups?: InputMaybe<Scalars['Boolean']>;
  exerciseId: Scalars['String'];
  personalizeAutomatically?: InputMaybe<Scalars['Boolean']>;
  position: Scalars['Float'];
  restAfterLastSet: Scalars['Float'];
  restBetweenSets: Scalars['Float'];
  sets: Array<CreateCircuitExerciseSetDto>;
  type: CircuitExerciseType;
};

/** Create set for a circuit exercise */
export type CreateCircuitExerciseSetDto = {
  position: Scalars['Float'];
  reps?: InputMaybe<Scalars['Float']>;
  toFailure: Scalars['Boolean'];
};

/** Create a new circuit */
export type CreateCircuitInput = {
  circuitExercises: Array<CreateCircuitExerciseDto>;
};

/** Create a new equipment */
export type CreateEquipmentInput = {
  imageId?: InputMaybe<Scalars['String']>;
  translations: Array<CreateEquipmentTranslationsInput>;
};

/** Create translations of a new equipment */
export type CreateEquipmentTranslationsInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
};

export type CreateExerciseAlternativesGroupExercisesDto = {
  exerciseId: Scalars['String'];
};

/** Create a new exerciseAlternativesGroup */
export type CreateExerciseAlternativesGroupInput = {
  exercises?: InputMaybe<Array<CreateExerciseAlternativesGroupExercisesDto>>;
  slug: Scalars['String'];
};

export type CreateExerciseEquipmentsDto = {
  equipmentId: Scalars['String'];
};

/** Create a new exercise history row */
export type CreateExerciseHistoryInput = {
  circuitExercisesId: Scalars['String'];
  exerciseId: Scalars['String'];
  position: Scalars['Float'];
  setHistories: Array<CreateSetHistoryInput>;
  type: CircuitExerciseType;
};

/** Create a new exercise */
export type CreateExerciseInput = {
  bodyweight: Scalars['Boolean'];
  equipments?: InputMaybe<Array<CreateExerciseEquipmentsDto>>;
  fullScreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  mainMuscleGroupId?: InputMaybe<Scalars['String']>;
  repDuration?: InputMaybe<Scalars['Float']>;
  secondaryMuscleGroupId?: InputMaybe<Scalars['String']>;
  translations: Array<CreateExerciseTranslationsInput>;
};

/** Create translations of a new exercise */
export type CreateExerciseTranslationsInput = {
  description: Scalars['String'];
  info: Scalars['String'];
  locale: Scalars['String'];
  title: Scalars['String'];
};

/** Create a new generic workout */
export type CreateGenericWorkoutDto = {
  backgroundVideoId?: InputMaybe<Scalars['String']>;
  timeTrial?: InputMaybe<Scalars['Boolean']>;
  timeTrialDuration?: InputMaybe<Scalars['Float']>;
};

export type CreateIngredientCategoriesDto = {
  categoryId: Scalars['String'];
};

/** Create a new ingredientCategory */
export type CreateIngredientCategoryInput = {
  slug: Scalars['String'];
  translations: Array<CreateIngredientCategoryTranslationsInput>;
};

/** Create translations of a new ingredientCategory */
export type CreateIngredientCategoryTranslationsInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
};

/** Create a new ingredient */
export type CreateIngredientInput = {
  categories?: InputMaybe<Array<CreateIngredientCategoriesDto>>;
  imageId?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Float']>;
  translations: Array<CreateIngredientTranslationsInput>;
  type: IngredientType;
};

/** Create translations of a new ingredient */
export type CreateIngredientTranslationsInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
};

export type CreateMeasurementHistoryInput = {
  date: Scalars['DateTime'];
  notes?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Boolean']>;
  pictures?: InputMaybe<Array<CreateMeasurementPictureInput>>;
  waistline?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type CreateMeasurementPictureInput = {
  /** When provided, the url and thumbnail url rows will return this value until the image has been fully uploaded and processed by the queue. This lets the frontend implement optimistic UI without additional developments. */
  localDeviceTempFilePath?: InputMaybe<Scalars['String']>;
};

/** Create a new muscleGroup */
export type CreateMuscleGroupInput = {
  slug: Scalars['String'];
  translations: Array<CreateMuscleGroupTranslationsInput>;
};

/** Create translations of a new muscleGroup */
export type CreateMuscleGroupTranslationsInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
};

/** Create a new objective */
export type CreateObjectiveInput = {
  imageId?: InputMaybe<Scalars['String']>;
  translations: Array<CreateObjectiveTranslationsInput>;
};

/** Create translations of a new objective */
export type CreateObjectiveTranslationsInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
};

/** Create the equipments of a program */
export type CreateProgramEquipments = {
  equipmentId: Scalars['String'];
};

/** Create a new program */
export type CreateProgramInput = {
  averageWorkoutDuration?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  equipments?: InputMaybe<Array<CreateProgramEquipments>>;
  fullscreenImageId?: InputMaybe<Scalars['String']>;
  gym: Scalars['Boolean'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  home: Scalars['Boolean'];
  imageId?: InputMaybe<Scalars['String']>;
  level: ProgramLevel;
  maxDays?: InputMaybe<Scalars['Float']>;
  maxDuration?: InputMaybe<Scalars['Float']>;
  minDays?: InputMaybe<Scalars['Float']>;
  minDuration?: InputMaybe<Scalars['Float']>;
  objectives?: InputMaybe<Array<CreateProgramObjectivesDto>>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tags?: InputMaybe<Array<CreaterProgramTagsDto>>;
  targetedAreas?: InputMaybe<Array<CreateProgramTargetedAreasDto>>;
  translations: Array<CreateProgramTranslationsInput>;
  type?: InputMaybe<ProgramType>;
  weeks?: InputMaybe<Array<CreateProgramWeeksDto>>;
};

/** Create the objectives of a program */
export type CreateProgramObjectivesDto = {
  objectiveId: Scalars['String'];
};

/** Create a new program tag */
export type CreateProgramTagInput = {
  internalName: Scalars['String'];
  position: Scalars['Float'];
  slug: Scalars['String'];
  translations: Array<CreateProgramTagTranslationsInput>;
};

/** Create translations of a new programTag */
export type CreateProgramTagTranslationsInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
};

/** Create the targeted areas of a program */
export type CreateProgramTargetedAreasDto = {
  targetedAreaId: Scalars['String'];
};

/** Create translations of a new program */
export type CreateProgramTranslationsInput = {
  description?: InputMaybe<Scalars['String']>;
  equipment?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Create a new program group */
export type CreateProgramVariationGroupInput = {
  internalName: Scalars['String'];
};

/** Create a program week  */
export type CreateProgramWeekDto = {
  items: Array<CreateProgramWeekItem>;
};

/** Create an item within a program week */
export type CreateProgramWeekItem = {
  position: Scalars['Float'];
  restDayId?: InputMaybe<Scalars['String']>;
  workoutId?: InputMaybe<Scalars['String']>;
};

/** Create the association between a program and a programWeek */
export type CreateProgramWeeksDto = {
  position: Scalars['Float'];
  programWeek: CreateProgramWeekDto;
};

export type CreateRecipeDto = {
  calories: Scalars['Float'];
  carbs: Scalars['Float'];
  cookTime: Scalars['Float'];
  fat: Scalars['Float'];
  fullscreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  ingredients: Array<CreateRecipeIngredientsDto>;
  preparationTime: Scalars['Float'];
  proteins: Scalars['Float'];
  servings: Scalars['Float'];
  tags?: InputMaybe<Array<CreateRecipeTagsDto>>;
  translations: Array<CreateRecipeTranslationsDto>;
};

export type CreateRecipeIngredientsDto = {
  ingredientId: Scalars['String'];
  quantity: Scalars['Float'];
  volume: Scalars['Float'];
  weight: Scalars['Float'];
};

/** Create a new recipeTag */
export type CreateRecipeTagInput = {
  color: Scalars['String'];
  translations: Array<CreateRecipeTagTranslationsInput>;
};

/** Create translations of a new recipeTag */
export type CreateRecipeTagTranslationsInput = {
  locale: Scalars['String'];
  title: Scalars['String'];
};

export type CreateRecipeTagsDto = {
  tagId: Scalars['String'];
};

export type CreateRecipeTranslationsDto = {
  content: Scalars['String'];
  locale: Scalars['String'];
  summary?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Create a new recovery workout */
export type CreateRecoveryWorkoutDto = {
  duration?: InputMaybe<Scalars['Float']>;
};

export type CreateRestDayDto = {
  fullScreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  translations: Array<CreateRestDayTranslationsDto>;
};

/** Create a new rest day history row */
export type CreateRestDayHistoryInput = {
  position?: InputMaybe<Scalars['Float']>;
  programWeekId?: InputMaybe<Scalars['String']>;
  restDayId: Scalars['String'];
};

export type CreateRestDayTranslationsDto = {
  internalName?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  quoteAuthor?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Create a new set history row */
export type CreateSetHistoryInput = {
  position: Scalars['Float'];
  scored?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Float']>;
};

/** Create a new targetedArea */
export type CreateTargetedAreaInput = {
  color: Scalars['String'];
  slug: Scalars['String'];
  translations: Array<CreateTargetedAreaTranslationsInput>;
};

/** Create translations of a new targetedArea */
export type CreateTargetedAreaTranslationsInput = {
  locale: Scalars['String'];
  title: Scalars['String'];
};

/** Create a new user program by joining a public program */
export type CreateUserProgramInput = {
  programId: Scalars['String'];
};

/** Create new user programs association by joining several programs at once from the admin */
export type CreateUserProgramsFromAdminInput = {
  programIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

/** Create a new userSubscription */
export type CreateUserSubscriptionInput = {
  endDate: Scalars['DateTime'];
  note?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  userId: Scalars['ID'];
};

/** Create a new workoutCategory */
export type CreateWorkoutCategoryInput = {
  color: Scalars['String'];
  slug: Scalars['String'];
  translations: Array<CreateWorkoutCategoryTranslationsInput>;
};

/** Create translations of a new workoutCategory */
export type CreateWorkoutCategoryTranslationsInput = {
  locale: Scalars['String'];
  title: Scalars['String'];
};

/** Create a new workout history row */
export type CreateWorkoutHistoryInput = {
  duration: Scalars['Float'];
  exerciseHistories: Array<CreateExerciseHistoryInput>;
  position?: InputMaybe<Scalars['Float']>;
  programWeekId?: InputMaybe<Scalars['String']>;
  totalReps: Scalars['Float'];
  workoutId: Scalars['String'];
};

/** Create a new workout */
export type CreateWorkoutInput = {
  actualDuration?: InputMaybe<Scalars['Float']>;
  authorId?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<UpdateWorkoutCategoriesDto>>;
  circuits?: InputMaybe<Array<UpdateWorkoutCircuitsDto>>;
  estimatedDuration?: InputMaybe<Scalars['Float']>;
  fullscreenImageId?: InputMaybe<Scalars['String']>;
  genericWorkout?: InputMaybe<CreateGenericWorkoutDto>;
  imageId?: InputMaybe<Scalars['ID']>;
  level?: InputMaybe<Scalars['Float']>;
  publicationStatus?: InputMaybe<Scalars['String']>;
  recoveryWorkout?: InputMaybe<CreateRecoveryWorkoutDto>;
  targetedAreas?: InputMaybe<Array<UpdateWorkoutTargetedAreasDto>>;
  translations: Array<CreateWorkoutTranslationsInput>;
  type: WorkoutType;
  warmupId?: InputMaybe<Scalars['String']>;
};

/** Create translations of a new workout */
export type CreateWorkoutTranslationsInput = {
  bodyFocus?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  hashtags?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Create the tags of a program */
export type CreaterProgramTagsDto = {
  tagId: Scalars['String'];
};

/** Date range for filtering. Min and max values are optional */
export type DateRange = {
  max?: InputMaybe<Scalars['DateTime']>;
  min?: InputMaybe<Scalars['DateTime']>;
};

/** Delete a user program by leaving a program from the admin */
export type DeleteUserProgramFromAdminInput = {
  programId: Scalars['String'];
  userId: Scalars['String'];
};

/** Delete a user's response */
export type DeleteUserType = {
  __typename?: 'DeleteUserType';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeletedRowsType = {
  __typename?: 'DeletedRowsType';
  count: Scalars['Float'];
};

/** Workout equipment composed of weeks */
export type Equipment = {
  __typename?: 'Equipment';
  createdAt: Scalars['DateTime'];
  home: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  translations: EquipmentI18N;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter equipments */
export type EquipmentFiltersType = {
  /** Filter equipments by ids */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Filter equipments by name */
  name?: InputMaybe<Scalars['String']>;
};

/** Translated fields of equipments */
export type EquipmentI18N = {
  __typename?: 'EquipmentI18N';
  name: Scalars['String'];
};

/** Used to order equipments */
export type EquipmentOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<EquipmentOrderProperties>;
};

/** Equipment properties that can be ordered */
export enum EquipmentOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Workout exercise composed of weeks */
export type Exercise = {
  __typename?: 'Exercise';
  alternativesGroups?: Maybe<Array<ExerciseAlternativesGroups>>;
  author: UserType;
  bodyweight: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  equipments?: Maybe<Array<ExerciseEquipments>>;
  fullScreenImage?: Maybe<MediaType>;
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  level?: Maybe<Scalars['Float']>;
  mainMuscleGroup?: Maybe<MuscleGroup>;
  repDuration?: Maybe<Scalars['Float']>;
  secondaryMuscleGroup?: Maybe<MuscleGroup>;
  translations: ExerciseI18N;
  updatedAt: Scalars['DateTime'];
  videos?: Maybe<Array<ExerciseVideo>>;
};

/** Multiple ingredient category assigned to ingredients */
export type ExerciseAlternativesGroup = {
  __typename?: 'ExerciseAlternativesGroup';
  createdAt: Scalars['DateTime'];
  exercises?: Maybe<Array<ExerciseAlternativesGroupsExercises>>;
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Used to filter exerciseAlternativesGroups */
export type ExerciseAlternativesGroupFiltersType = {
  /** Filter exercise alternatives groups by ids */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Filter exercise alternatives groups by slug */
  slug?: InputMaybe<Scalars['String']>;
};

/** Used to order exerciseAlternativesGroups */
export type ExerciseAlternativesGroupOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ExerciseAlternativesGroupOrderProperties>;
};

/** ExerciseAlternativesGroup properties that can be ordered */
export enum ExerciseAlternativesGroupOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type ExerciseAlternativesGroups = {
  __typename?: 'ExerciseAlternativesGroups';
  exerciseAlternativesGroup: ExerciseAlternativesGroup;
  id: Scalars['ID'];
};

export type ExerciseAlternativesGroupsExercises = {
  __typename?: 'ExerciseAlternativesGroupsExercises';
  exercise: Exercise;
  id: Scalars['ID'];
};

export type ExerciseEquipments = {
  __typename?: 'ExerciseEquipments';
  equipment: Equipment;
  id: Scalars['ID'];
};

/** Used to filter exercises */
export type ExerciseFiltersType = {
  exerciseIds?: InputMaybe<Array<Scalars['String']>>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Filter program by name */
  title?: InputMaybe<Scalars['String']>;
};

/** ExerciseHistories summary for a given exerciseId */
export type ExerciseHistoriesSummary = {
  __typename?: 'ExerciseHistoriesSummary';
  records?: Maybe<ExerciseHistoriesSummaryRecords>;
  workouts: Array<ExerciseHistoriesSummaryWorkout>;
};

/** ExerciseHistories summary record */
export type ExerciseHistoriesSummaryRecord = {
  __typename?: 'ExerciseHistoriesSummaryRecord';
  date?: Maybe<Scalars['String']>;
  scored?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** ExerciseHistories summary records */
export type ExerciseHistoriesSummaryRecords = {
  __typename?: 'ExerciseHistoriesSummaryRecords';
  maxScored: ExerciseHistoriesSummaryRecord;
  maxWeight: ExerciseHistoriesSummaryRecord;
};

/** Used to query exerciseHistories summary */
export type ExerciseHistoriesSummaryType = {
  /** Filter exerciseHistories summary by exerciseId */
  exerciseId: Scalars['String'];
  /** Filter exerciseHistories summary by circuitExercise type */
  type: CircuitExerciseType;
};

/** ExerciseHistories summary workout */
export type ExerciseHistoriesSummaryWorkout = {
  __typename?: 'ExerciseHistoriesSummaryWorkout';
  date: Scalars['String'];
  exercises: Array<ExerciseHistoriesSummaryWorkoutExercise>;
  title: Scalars['String'];
  workoutHistoryId: Scalars['String'];
  workoutId: Scalars['String'];
};

/** ExerciseHistories summary workout exercise */
export type ExerciseHistoriesSummaryWorkoutExercise = {
  __typename?: 'ExerciseHistoriesSummaryWorkoutExercise';
  exerciseHistoryId: Scalars['String'];
  sets: Array<ExerciseHistoriesSummaryWorkoutExerciseSet>;
};

/** ExerciseHistories summary workout exercise set */
export type ExerciseHistoriesSummaryWorkoutExerciseSet = {
  __typename?: 'ExerciseHistoriesSummaryWorkoutExerciseSet';
  position: Scalars['Float'];
  scored?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** ExerciseHistory */
export type ExerciseHistory = {
  __typename?: 'ExerciseHistory';
  createdAt: Scalars['DateTime'];
  exercise: Exercise;
  exerciseId: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Float'];
  setHistories: Array<SetHistory>;
  type: CircuitExerciseType;
  updatedAt: Scalars['DateTime'];
  user: UserType;
  workoutHistoryId: Scalars['String'];
};

/** Used to filter exerciseHistories */
export type ExerciseHistoryFiltersType = {
  /** Filter exerciseHistory by exerciseId */
  exerciseId?: InputMaybe<Scalars['String']>;
  /** Filter exerciseHistory by circuitExercise type */
  type?: InputMaybe<CircuitExerciseType>;
  /** Filter exerciseHistory by workoutHistoryId */
  workoutHistoryId?: InputMaybe<Scalars['String']>;
};

/** Used to order programs */
export type ExerciseHistoryOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ExerciseHistoryOrderProperties>;
};

/** ExerciseHistory properties that can be ordered */
export enum ExerciseHistoryOrderProperties {
  CreatedAt = 'createdAt',
  Position = 'position',
  UpdatedAt = 'updatedAt'
}

/** Translated fields of exercises */
export type ExerciseI18N = {
  __typename?: 'ExerciseI18N';
  description?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Used to order exercises */
export type ExerciseOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ExerciseOrderProperties>;
};

/** Exercise properties that can be ordered */
export enum ExerciseOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Video associated to an exercise */
export type ExerciseVideo = {
  __typename?: 'ExerciseVideo';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: ExerciseVideoTypes;
  updatedAt: Scalars['DateTime'];
  video?: Maybe<MediaType>;
};

/**
 *
 *         Different types of workout videos.
 *
 *         * `excerpt`: Short videos displayed in the guided workout, usually in landscape
 *         * `full`: Complete videos displayed in modals, usually in portrait
 *
 */
export enum ExerciseVideoTypes {
  Excerpt = 'Excerpt',
  Full = 'Full'
}

/** Translated fields of explore article */
export type ExploreArticleI18N = {
  __typename?: 'ExploreArticleI18N';
  excerpt?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  title: Scalars['String'];
  workoutsListTitle?: Maybe<Scalars['String']>;
};

/** Explore article type */
export type ExploreArticleType = {
  __typename?: 'ExploreArticleType';
  articleId: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  translations: Array<ExploreArticleI18N>;
  workoutIds: Array<Scalars['ID']>;
};

/** Used to query articles */
export type FindArticle = {
  /** Filter program results */
  filters?: InputMaybe<ArticleFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ArticleOrderByType>>;
};

/** Used to query articleCategories */
export type FindArticleCategory = {
  /** Filter program results */
  filters?: InputMaybe<ArticleCategoryFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ArticleCategoryOrderByType>>;
};

export type FindArticleCategoryResultType = {
  __typename?: 'FindArticleCategoryResultType';
  data: Array<ArticleCategory>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query articles */
export type FindArticleFavorite = {
  /** Properties which should be ordered */
  articleOrder?: InputMaybe<Array<ArticleOrderByType>>;
  /** Filter article results */
  filters?: InputMaybe<ArticleFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ArticleFavoriteOrderByType>>;
};

export type FindArticleFavoriteResultType = {
  __typename?: 'FindArticleFavoriteResultType';
  data: Array<ArticleFavorite>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query articleGroups */
export type FindArticleGroup = {
  /** Filter article groups results */
  filters?: InputMaybe<ArticleGroupFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
};

export type FindArticleGroupResultType = {
  __typename?: 'FindArticleGroupResultType';
  data: Array<ArticleGroup>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindArticleResultType = {
  __typename?: 'FindArticleResultType';
  data: Array<Article>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query collages */
export type FindCollage = {
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<CollageOrderByType>>;
};

export type FindCollageResultType = {
  __typename?: 'FindCollageResultType';
  data: Array<Collage>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query equipments */
export type FindEquipment = {
  /** Filter program results */
  filters?: InputMaybe<EquipmentFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<EquipmentOrderByType>>;
};

export type FindEquipmentResultType = {
  __typename?: 'FindEquipmentResultType';
  data: Array<Equipment>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query exercises */
export type FindExercise = {
  /** Filter program results */
  filters?: InputMaybe<ExerciseFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ExerciseOrderByType>>;
};

/** Used to query exerciseAlternativesGroups */
export type FindExerciseAlternativesGroup = {
  /** Filter exerciseAlternativesGroups results */
  filters?: InputMaybe<ExerciseAlternativesGroupFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ExerciseAlternativesGroupOrderByType>>;
};

export type FindExerciseAlternativesGroupResultType = {
  __typename?: 'FindExerciseAlternativesGroupResultType';
  data: Array<ExerciseAlternativesGroup>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query exerciseHistories */
export type FindExerciseHistory = {
  /** Filter exerciseHistory results */
  filters?: InputMaybe<ExerciseHistoryFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ExerciseHistoryOrderByType>>;
};

export type FindExerciseHistoryResultType = {
  __typename?: 'FindExerciseHistoryResultType';
  data: Array<ExerciseHistory>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindExerciseResultType = {
  __typename?: 'FindExerciseResultType';
  data: Array<Exercise>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query ingredients */
export type FindIngredient = {
  /** Filter ingredient results */
  filters?: InputMaybe<IngredientFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<IngredientOrderByType>>;
};

/** Used to query ingredientCategories */
export type FindIngredientCategory = {
  /** Filter ingredientCategories results */
  filters?: InputMaybe<IngredientCategoryFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<IngredientCategoryOrderByType>>;
};

export type FindIngredientCategoryResultType = {
  __typename?: 'FindIngredientCategoryResultType';
  data: Array<IngredientCategory>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindIngredientResultType = {
  __typename?: 'FindIngredientResultType';
  data: Array<Ingredient>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindMeasurementHistory = {
  filters?: InputMaybe<MeasurementHistoryFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<MeasurementHistoryOrderByType>>;
};

export type FindMeasurementHistoryResultType = {
  __typename?: 'FindMeasurementHistoryResultType';
  data: Array<MeasurementHistory>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query medias */
export type FindMedia = {
  /** Filter media results */
  filters?: InputMaybe<MediaFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<MediaOrderByType>>;
};

export type FindMediaResultType = {
  __typename?: 'FindMediaResultType';
  data: Array<MediaType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindMetadataResultType = {
  __typename?: 'FindMetadataResultType';
  data: Scalars['String'];
};

/** Find the most recent history for a given array of workout ids */
export type FindMostRecentWorkoutHistory = {
  /** Array of rest day ids */
  restDayIds?: InputMaybe<Array<Scalars['String']>>;
  /** Array of workout ids */
  workoutIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Find the most recent history for a given given week.Please note that it checks for potential progression resets, contrary to mostRecentWorkoutHistory(). */
export type FindMostRecentWorkoutHistoryInWeek = {
  /** Program week id */
  weekId: Scalars['String'];
};

/** Used to query muscleGroups */
export type FindMuscleGroup = {
  /** Filter program results */
  filters?: InputMaybe<MuscleGroupFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<MuscleGroupOrderByType>>;
};

export type FindMuscleGroupResultType = {
  __typename?: 'FindMuscleGroupResultType';
  data: Array<MuscleGroup>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query objectives */
export type FindObjective = {
  /** Filter program results */
  filters?: InputMaybe<ObjectiveFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ObjectiveOrderByType>>;
};

export type FindObjectiveResultType = {
  __typename?: 'FindObjectiveResultType';
  data: Array<Objective>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query programs */
export type FindProgram = {
  /** Distinct programs */
  distinct?: InputMaybe<ProgramDistinctType>;
  /** Filter program results */
  filters?: InputMaybe<ProgramFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ProgramOrderByType>>;
};

export type FindProgramProgression = {
  filters?: InputMaybe<ProgramProgressionFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProgramProgressionOrderByType>>;
};

export type FindProgramProgressionResultType = {
  __typename?: 'FindProgramProgressionResultType';
  data: Array<ProgramProgression>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindProgramResultType = {
  __typename?: 'FindProgramResultType';
  data: Array<Program>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query program tags */
export type FindProgramTag = {
  /** Used to filter programTags */
  filters?: InputMaybe<ProgramTagsFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ProgramTagOrderByType>>;
};

export type FindProgramTagResultType = {
  __typename?: 'FindProgramTagResultType';
  data: Array<ProgramTag>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query program groups */
export type FindProgramVariationGroup = {
  /** Filter programVariationGroups results */
  filters?: InputMaybe<ProgramVariationGroupFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Filter program groups with program filters */
  programFilters?: InputMaybe<ProgramFiltersType>;
  /** Properties which should be ordered */
  programOrder?: InputMaybe<Array<ProgramOrderByType>>;
};

export type FindProgramVariationGroupResultType = {
  __typename?: 'FindProgramVariationGroupResultType';
  data: Array<ProgramVariationGroup>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query progressPictures */
export type FindProgressPicture = {
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ProgressPictureOrderByType>>;
};

export type FindProgressPictureResultType = {
  __typename?: 'FindProgressPictureResultType';
  data: Array<ProgressPicture>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query recipes */
export type FindRecipe = {
  /** Filter program results */
  filters?: InputMaybe<RecipeFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<RecipeOrderByType>>;
};

/** Used to query recipes */
export type FindRecipeFavorite = {
  /** Filter recipe results */
  filters?: InputMaybe<RecipeFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<RecipeFavoriteOrderByType>>;
  /** Properties which should be ordered */
  recipeOrder?: InputMaybe<Array<RecipeOrderByType>>;
};

export type FindRecipeFavoriteResultType = {
  __typename?: 'FindRecipeFavoriteResultType';
  data: Array<RecipeFavorite>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query recipeLists */
export type FindRecipeList = {
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<RecipeListOrderByType>>;
};

/** Used to query recipeLists items */
export type FindRecipeListItems = {
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<RecipeListItemOrderByType>>;
  recipeListId?: InputMaybe<Scalars['String']>;
  recipeListSlug?: InputMaybe<Scalars['String']>;
};

export type FindRecipeListItemsResultType = {
  __typename?: 'FindRecipeListItemsResultType';
  data: Array<RecipeListItems>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindRecipeListResultType = {
  __typename?: 'FindRecipeListResultType';
  data: Array<RecipeList>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindRecipeResultType = {
  __typename?: 'FindRecipeResultType';
  data: Array<Recipe>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query recipeTags */
export type FindRecipeTag = {
  /** Filter program results */
  filters?: InputMaybe<RecipeTagFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<RecipeTagOrderByType>>;
};

export type FindRecipeTagResultType = {
  __typename?: 'FindRecipeTagResultType';
  data: Array<RecipeTag>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Criteria chosen by the user during the onboarding that will help recommending the best program */
export type FindRecommendedProgram = {
  programEquipment: ProgramEquipment;
  programLevel: ProgramLevel;
  programWorkoutDuration: ProgramWorkoutDuration;
};

/** Used to query restDays */
export type FindRestDay = {
  /** Filter rest day options */
  filters?: InputMaybe<RestDayFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<RestDayOrderByType>>;
};

export type FindRestDayResultType = {
  __typename?: 'FindRestDayResultType';
  data: Array<RestDay>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query global stats */
export type FindStats = {
  /** Statistics end date */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Statistics start date */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FindSwappedExercisesResultType = {
  __typename?: 'FindSwappedExercisesResultType';
  data: Array<AlternativeExerciseType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query targetedareas */
export type FindTargetedArea = {
  /** Filter targetedareas results */
  filters?: InputMaybe<TargetedAreaFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<TargetedAreaOrderByType>>;
};

export type FindTargetedAreaResultType = {
  __typename?: 'FindTargetedAreaResultType';
  data: Array<TargetedArea>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to find a recipe list */
export type FindUniqueRecipeList = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Find unique workout category */
export type FindUniqueTargetedArea = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Find unique workout category */
export type FindUniqueWorkoutCategory = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Find unique workout lists (by id, slug...) */
export type FindUniqueWorkoutList = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Used to query users */
export type FindUser = {
  /** Filter program results */
  filters?: InputMaybe<UserFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<UserOrderByType>>;
};

export type FindUserConnectionMethodsType = {
  __typename?: 'FindUserConnectionMethodsType';
  connectionMethods: Array<ConnectionMethods>;
};

/** Used to query requesting user current subscription */
export type FindUserCurrentSubscriptionResultType = {
  __typename?: 'FindUserCurrentSubscriptionResultType';
  active: Scalars['Boolean'];
  /** Current subscription of the user */
  subscription?: Maybe<UserSubscription>;
};

/** Used to query userEvents */
export type FindUserEvent = {
  /** Filter user events results */
  filters?: InputMaybe<UserEventFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<UserEventOrderByType>>;
};

export type FindUserEventResultType = {
  __typename?: 'FindUserEventResultType';
  data: Array<UserEvent>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindUserFirstNameType = {
  __typename?: 'FindUserFirstNameType';
  firstName: Scalars['String'];
};

/** Used to query programs joined by a user */
export type FindUserJoinedProgram = {
  /** Filter program results */
  filters?: InputMaybe<ProgramFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<ProgramOrderByType>>;
  /** The id of the user */
  userId: Scalars['String'];
};

export type FindUserResultType = {
  __typename?: 'FindUserResultType';
  data: Array<UserType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query userSubscriptions */
export type FindUserSubscription = {
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<UserSubscriptionOrderByType>>;
};

export type FindUserSubscriptionResultType = {
  __typename?: 'FindUserSubscriptionResultType';
  data: Array<UserSubscription>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query user stats */
export type FindUsersStats = {
  /** Statistics end date */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Statistics start date */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

/** Used to query workouts */
export type FindWorkout = {
  /** Filter workout results */
  filters?: InputMaybe<WorkoutFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<WorkoutOrderByType>>;
};

/** Used to query workoutCategories */
export type FindWorkoutCategory = {
  /** Filter workoutCategories results */
  filters?: InputMaybe<WorkoutCategoryFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<WorkoutCategoryOrderByType>>;
};

export type FindWorkoutCategoryResultType = {
  __typename?: 'FindWorkoutCategoryResultType';
  data: Array<WorkoutCategory>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query workouts */
export type FindWorkoutFavorite = {
  /** Filter workout results */
  filters?: InputMaybe<WorkoutFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<WorkoutFavoriteOrderByType>>;
  /** Properties which should be ordered */
  workoutOrder?: InputMaybe<Array<WorkoutOrderByType>>;
};

export type FindWorkoutFavoriteResultType = {
  __typename?: 'FindWorkoutFavoriteResultType';
  data: Array<WorkoutFavorite>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Used to query workoutHistories */
export type FindWorkoutHistory = {
  /** Filter workoutHistory results */
  filters?: InputMaybe<WorkoutHistoryFiltersType>;
  /** The number of element index to retrieve, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** The first element index to retrieve, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Properties which should be ordered */
  order?: InputMaybe<Array<WorkoutHistoryOrderByType>>;
};

export type FindWorkoutHistoryResultType = {
  __typename?: 'FindWorkoutHistoryResultType';
  data: Array<WorkoutHistory>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type FindWorkoutResultType = {
  __typename?: 'FindWorkoutResultType';
  data: Array<Workout>;
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

/** Generic workout */
export type GenericWorkout = {
  __typename?: 'GenericWorkout';
  backgroundVideo?: Maybe<MediaType>;
  id: Scalars['ID'];
  timeTrial?: Maybe<Scalars['Boolean']>;
  timeTrialDuration?: Maybe<Scalars['Float']>;
};

export type GooglePaymentProcessedType = {
  __typename?: 'GooglePaymentProcessedType';
  isCanceled: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
};

/** Google receipt payload required for validation from client-side (initial purchase or restoration) */
export type GoogleReceiptPayloadInput = {
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  autoRenewing: Scalars['Boolean'];
  orderId: Scalars['String'];
  packageName: Scalars['String'];
  productId: Scalars['String'];
  purchaseState: Scalars['Float'];
  purchaseTime: Scalars['Float'];
  purchaseToken: Scalars['String'];
  quantity?: InputMaybe<Scalars['Float']>;
};

export type ImpersonateUserDto = {
  email: Scalars['String'];
};

/** Ingredient for recipes */
export type Ingredient = {
  __typename?: 'Ingredient';
  categories?: Maybe<Array<IngredientCategories>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  score?: Maybe<Scalars['Float']>;
  translations: IngredientI18n;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type IngredientCategories = {
  __typename?: 'IngredientCategories';
  category: IngredientCategory;
  id: Scalars['ID'];
};

/** Multiple ingredient category assigned to ingredients */
export type IngredientCategory = {
  __typename?: 'IngredientCategory';
  id: Scalars['ID'];
  slug: Scalars['String'];
  translations: IngredientCategoryI18N;
};

/** Used to filter ingredientCategories */
export type IngredientCategoryFiltersType = {
  /** Filter ingredient category by name */
  name?: InputMaybe<Scalars['String']>;
};

/** Translated fields of ingredientCategories */
export type IngredientCategoryI18N = {
  __typename?: 'IngredientCategoryI18N';
  name: Scalars['String'];
};

/** Used to order ingredientCategories */
export type IngredientCategoryOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<IngredientCategoryOrderProperties>;
};

/** IngredientCategory properties that can be ordered */
export enum IngredientCategoryOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Used to filter ingredients */
export type IngredientFiltersType = {
  /** Filter ingredient by score (maximum) */
  maximumScore?: InputMaybe<Scalars['Float']>;
  /** Filter ingredient by score (minimum) */
  minimumScore?: InputMaybe<Scalars['Float']>;
  /** Filter ingredient by name */
  name?: InputMaybe<Scalars['String']>;
  /** Filter ingredient by score */
  score?: InputMaybe<NumericFilterType>;
  /** Filter ingredient by ingredient type */
  type?: InputMaybe<IngredientType>;
};

/** Translated fields of ingredients */
export type IngredientI18n = {
  __typename?: 'IngredientI18n';
  name: Scalars['String'];
};

/** Used to order programs */
export type IngredientOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<IngredientOrderProperties>;
};

/** Ingredient properties that can be ordered */
export enum IngredientOrderProperties {
  CreatedAt = 'createdAt',
  Score = 'score',
  UpdatedAt = 'updatedAt'
}

/** The different types of ingredient */
export enum IngredientType {
  Liquid = 'LIQUID',
  Solid = 'SOLID'
}

/** Program and week progression that were initialized (if needed) */
export type InitializedUserProgression = {
  __typename?: 'InitializedUserProgression';
  programProgression: ProgramProgression;
  programWeekProgression: WeekProgression;
};

export type JoinProgramsResultType = {
  __typename?: 'JoinProgramsResultType';
  count: Scalars['Float'];
  /** The number of element index to retrieve, defaults to 20 */
  limit: Scalars['Int'];
  /** The first element index to retrieve, defaults to 0 */
  offset: Scalars['Int'];
  /** The total number of elements */
  total: Scalars['Int'];
};

export type LogUserInDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LogUserInWithAppleDto = {
  id?: InputMaybe<Scalars['String']>;
  identityToken?: InputMaybe<Scalars['String']>;
};

export type LogUserInWithGoogleDto = {
  idToken: Scalars['String'];
};

export type LogUserInWithTransactionIdDto = {
  platform: PaymentPlatform;
  transactionId: Scalars['String'];
};

export type LoggedInSocialsType = {
  __typename?: 'LoggedInSocialsType';
  accessToken: Scalars['String'];
  email: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type LoggedInType = {
  __typename?: 'LoggedInType';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type MagicallyLogUserInDto = {
  token: Scalars['String'];
};

/** MeasurementHistory */
export type MeasurementHistory = {
  __typename?: 'MeasurementHistory';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Boolean']>;
  pictures?: Maybe<Array<MeasurementPictureType>>;
  updatedAt: Scalars['DateTime'];
  waistline?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** Filter measurement histories */
export type MeasurementHistoryFiltersType = {
  dateRange?: InputMaybe<DateRange>;
  withPictures?: InputMaybe<Scalars['Boolean']>;
};

/** Order measurement histories */
export type MeasurementHistoryOrderByType = {
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<MeasurementHistoryOrderProperties>;
};

/** MeasurementHistory properties that can be ordered */
export enum MeasurementHistoryOrderProperties {
  CreatedAt = 'createdAt',
  Date = 'date',
  UpdatedAt = 'updatedAt'
}

/** MeasurementPictureType */
export type MeasurementPictureType = {
  __typename?: 'MeasurementPictureType';
  measurementPosition: Scalars['Float'];
  picture: UserUpload;
};

/** Source of external providers from where we imported the media */
export enum MediaExternalSources {
  Vimeo = 'VIMEO'
}

/** Used to filter medias */
export type MediaFiltersType = {
  /** Filter media by description */
  description?: InputMaybe<Scalars['String']>;
  /** Filter medias by fileName */
  fileName?: InputMaybe<Scalars['String']>;
  /** Filter medias by type */
  type?: InputMaybe<TypeOfMedia>;
};

/** Used to order muscleGroups */
export type MediaOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<MediaOrderProperties>;
};

/** Media properties that can be ordered */
export enum MediaOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type MediaType = {
  __typename?: 'MediaType';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  external: Scalars['Boolean'];
  externalId?: Maybe<Scalars['String']>;
  externalSource?: Maybe<MediaExternalSources>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  hls?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnailUrl: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

/** Workout muscleGroup composed of weeks */
export type MuscleGroup = {
  __typename?: 'MuscleGroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  translations: MuscleGroupI18N;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter muscleGroups */
export type MuscleGroupFiltersType = {
  /** Filter muscle group by name */
  name?: InputMaybe<Scalars['String']>;
};

/** Translated fields of muscleGroups */
export type MuscleGroupI18N = {
  __typename?: 'MuscleGroupI18N';
  name: Scalars['String'];
};

/** Used to order muscleGroups */
export type MuscleGroupOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<MuscleGroupOrderProperties>;
};

/** MuscleGroup properties that can be ordered */
export enum MuscleGroupOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type Mutation = {
  __typename?: 'Mutation';
  attachProgramToProgramVariationGroup: ProgramVariationGroup;
  attachTagToProgram: ProgramTag;
  bulkUpdateProgramTags: AffectedRowsType;
  changeProgressPictureDate: ProgressPicture;
  claimAccount: LoggedInType;
  coachingAssignCoachesToUser: Array<AssignCoachesToUserResponseType>;
  coachingAssignCoachingProgram: CoachingClientType;
  coachingRemoveCoachesFromUser: AffectedRowsType;
  coachingUpdateClientTags: AffectedRowsType;
  createArticle: Article;
  createArticleCategory: ArticleCategory;
  createArticleFavorite: ArticleFavorite;
  createArticleGroup: ArticleGroup;
  createEquipment: Equipment;
  createExercise: Exercise;
  createExerciseAlternativesGroup: ExerciseAlternativesGroup;
  createIngredient: Ingredient;
  createIngredientCategory: IngredientCategory;
  createInternalUserSubscriptionFromAdmin: UserSubscription;
  createMeasurementHistory: MeasurementHistory;
  createMuscleGroup: MuscleGroup;
  createObjective: Objective;
  createProgram: Program;
  createProgramTag: ProgramTag;
  createProgramVariationGroup: ProgramVariationGroup;
  createRecipe: Recipe;
  createRecipeFavorite: RecipeFavorite;
  createRecipeTag: RecipeTag;
  createRestDay: RestDay;
  createRestDayHistory: WorkoutHistory;
  createTargetedArea: TargetedArea;
  createWorkout: Workout;
  createWorkoutCategory: WorkoutCategory;
  createWorkoutFavorite: WorkoutFavorite;
  createWorkoutHistory: WorkoutHistory;
  deleteAllMeasurementHistories: DeletedRowsType;
  deleteArticleFavorite: AffectedRowsType;
  deleteArticles: AffectedRowsType;
  deleteArticlesForever: AffectedRowsType;
  deleteCollage: Collage;
  deleteExercises: AffectedRowsType;
  deleteMeasurementHistories: DeletedRowsType;
  deleteProgressPicture: ProgressPicture;
  deleteRecipeFavorite: AffectedRowsType;
  deleteRecipes: AffectedRowsType;
  deleteRecipesForever: AffectedRowsType;
  deleteUser: DeleteUserType;
  deleteWorkoutFavorite: AffectedRowsType;
  detachProgramFromProgramVariationGroup: ProgramVariationGroup;
  detachTagFromProgram: ProgramTag;
  initializeProgression: InitializedUserProgression;
  joinProgramsFromAdmin: JoinProgramsResultType;
  joinPublicProgram: UserProgram;
  leaveProgramFromAdmin: UserProgram;
  login: LoggedInType;
  loginWithApple: LoggedInSocialsType;
  loginWithGoogle: LoggedInSocialsType;
  loginWithImpersonation: LoggedInType;
  loginWithMagicToken: LoggedInSocialsType;
  loginWithStoreTransactionId: LoggedInType;
  orderWorkoutCircuits: Array<WorkoutCircuits>;
  refreshToken: LoggedInType;
  register: LoggedInType;
  registerBulk: AffectedRowsType;
  registerWithApple: LoggedInSocialsType;
  registerWithGoogle: LoggedInSocialsType;
  removeInternalUserSubscriptionFromAdmin: RemoveUserSubscriptionType;
  removeSelfUserPinCode: RemoveUserPinCodeResultType;
  removeUserPinCodeFromAdmin: RemoveUserPinCodeResultType;
  requestAccountDeletion: RequestAccountDeletionResultType;
  requestMagicLoginLink: RequestMagicLoginTokenResultType;
  requestMagicLoginLinkFromEmail: RequestUserCredentialResetResultType;
  requestUserPasswordReset: RequestUserCredentialResetResultType;
  requestUserPasswordResetLink: RequestUserResetPasswordLinkResultType;
  requestUserPinCodeReset: RequestUserCredentialResetResultType;
  resetProgramProgression: ProgramProgression;
  resetUserSwappedExercises: AffectedRowsType;
  resetWeekProgression: WeekProgression;
  restoreArticles: AffectedRowsType;
  restoreExercises: AffectedRowsType;
  restoreRecipes: AffectedRowsType;
  stripeCancelSubscription: StripeSubscriptionType;
  stripeCreateOrScheduleSubscription: StripeCreateOrScheduleSubscriptionResultType;
  stripeDeleteSubscriptionSchedule: StripeSubscriptionScheduleType;
  stripeDetachPaymentMethod: StripePaymentMethodType;
  stripeReactivateSubscription: StripeSubscriptionType;
  stripeSetDefaultPaymentMethod: StripePaymentMethodType;
  stripeUpdateSubscription: StripeSubscriptionType;
  stripeUpdateSubscriptionSchedule: StripeSubscriptionScheduleType;
  swapUserExercise: UserSwappedExercise;
  tempMigrateFacebookUsers: AffectedRowsType;
  transferUserAccount: TransferUserAccountResponseType;
  updateArticle: Article;
  updateArticleCategory: ArticleCategory;
  updateArticleGroup: ArticleGroup;
  updateEmail: UpdateUserEmailType;
  updateEquipment: Equipment;
  updateExercise: Exercise;
  updateExerciseAlternativesGroup: ExerciseAlternativesGroup;
  updateIngredient: Ingredient;
  updateIngredientCategory: IngredientCategory;
  updateInternalUserSubscriptionFromAdmin: UserSubscription;
  updateMeasurementHistory: MeasurementHistory;
  updateMuscleGroup: MuscleGroup;
  updateObjective: Objective;
  updateProgram: Program;
  updateProgramProgression: ProgramProgression;
  updateProgramTag: ProgramTag;
  updateProgramVariationGroup: ProgramVariationGroup;
  updateRecipe: Recipe;
  updateRecipeList: RecipeList;
  updateRecipeTag: RecipeTag;
  updateRestDay: RestDay;
  updateSelfUser: UserType;
  updateTargetedArea: TargetedArea;
  updateUserSettings: UserSettingsType;
  /** @deprecated Its only purpose is to reset week progressions. Please use the new resetWeekProgression mutation instead. */
  updateWeekProgression: WeekProgression;
  updateWorkout: Workout;
  updateWorkoutCategory: WorkoutCategory;
  validateAppleReceipt: ApplePaymentProcessedType;
  validateGoogleReceipt: GooglePaymentProcessedType;
  vimeoImportVideos: Array<VimeoImportationResultType>;
};


export type MutationAttachProgramToProgramVariationGroupArgs = {
  programId: Scalars['String'];
  programVariationGroupId: Scalars['String'];
};


export type MutationAttachTagToProgramArgs = {
  programId: Scalars['String'];
  tagId: Scalars['String'];
};


export type MutationBulkUpdateProgramTagsArgs = {
  input: Array<BulkUpdateProgramTagInput>;
};


export type MutationChangeProgressPictureDateArgs = {
  changeProgressPictureDateType: ChangeProgressPictureDateType;
};


export type MutationClaimAccountArgs = {
  input: ClaimAccountDto;
};


export type MutationCoachingAssignCoachesToUserArgs = {
  input: AssignCoachesToUserDto;
};


export type MutationCoachingAssignCoachingProgramArgs = {
  input: AssignCoachingProgramDto;
};


export type MutationCoachingRemoveCoachesFromUserArgs = {
  input: RemoveCoachesFromUserDto;
};


export type MutationCoachingUpdateClientTagsArgs = {
  input: UpdateCoachingClientTagsDto;
};


export type MutationCreateArticleArgs = {
  input: CreateArticleDto;
};


export type MutationCreateArticleCategoryArgs = {
  createArticleCategoryInput: CreateArticleCategoryInput;
};


export type MutationCreateArticleFavoriteArgs = {
  articleId: Scalars['String'];
};


export type MutationCreateArticleGroupArgs = {
  input: CreateArticleGroupInput;
};


export type MutationCreateEquipmentArgs = {
  createEquipmentInput: CreateEquipmentInput;
};


export type MutationCreateExerciseArgs = {
  input: CreateExerciseInput;
};


export type MutationCreateExerciseAlternativesGroupArgs = {
  createExerciseAlternativesGroupInput: CreateExerciseAlternativesGroupInput;
};


export type MutationCreateIngredientArgs = {
  createIngredientInput: CreateIngredientInput;
};


export type MutationCreateIngredientCategoryArgs = {
  createIngredientCategoryInput: CreateIngredientCategoryInput;
};


export type MutationCreateInternalUserSubscriptionFromAdminArgs = {
  createUserSubscriptionInput: CreateUserSubscriptionInput;
};


export type MutationCreateMeasurementHistoryArgs = {
  input: CreateMeasurementHistoryInput;
};


export type MutationCreateMuscleGroupArgs = {
  createMuscleGroupInput: CreateMuscleGroupInput;
};


export type MutationCreateObjectiveArgs = {
  createObjectiveInput: CreateObjectiveInput;
};


export type MutationCreateProgramArgs = {
  createProgramInput: CreateProgramInput;
};


export type MutationCreateProgramTagArgs = {
  input: CreateProgramTagInput;
};


export type MutationCreateProgramVariationGroupArgs = {
  input: CreateProgramVariationGroupInput;
};


export type MutationCreateRecipeArgs = {
  input: CreateRecipeDto;
};


export type MutationCreateRecipeFavoriteArgs = {
  recipeId: Scalars['String'];
};


export type MutationCreateRecipeTagArgs = {
  createRecipeTagInput: CreateRecipeTagInput;
};


export type MutationCreateRestDayArgs = {
  input: CreateRestDayDto;
};


export type MutationCreateRestDayHistoryArgs = {
  input: CreateRestDayHistoryInput;
};


export type MutationCreateTargetedAreaArgs = {
  createTargetedAreaInput: CreateTargetedAreaInput;
};


export type MutationCreateWorkoutArgs = {
  input: CreateWorkoutInput;
};


export type MutationCreateWorkoutCategoryArgs = {
  createWorkoutCategoryInput: CreateWorkoutCategoryInput;
};


export type MutationCreateWorkoutFavoriteArgs = {
  workoutId: Scalars['String'];
};


export type MutationCreateWorkoutHistoryArgs = {
  input: CreateWorkoutHistoryInput;
};


export type MutationDeleteArticleFavoriteArgs = {
  articleId: Scalars['String'];
};


export type MutationDeleteArticlesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteArticlesForeverArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteCollageArgs = {
  collageId: Scalars['String'];
};


export type MutationDeleteExercisesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteMeasurementHistoriesArgs = {
  measurementHistoryIds: Array<Scalars['String']>;
};


export type MutationDeleteProgressPictureArgs = {
  progressPictureId: Scalars['String'];
};


export type MutationDeleteRecipeFavoriteArgs = {
  recipeId: Scalars['String'];
};


export type MutationDeleteRecipesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteRecipesForeverArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteWorkoutFavoriteArgs = {
  workoutId: Scalars['String'];
};


export type MutationDetachProgramFromProgramVariationGroupArgs = {
  programId: Scalars['String'];
  programVariationGroupId: Scalars['String'];
};


export type MutationDetachTagFromProgramArgs = {
  programId: Scalars['String'];
  tagId: Scalars['String'];
};


export type MutationInitializeProgressionArgs = {
  programId: Scalars['String'];
};


export type MutationJoinProgramsFromAdminArgs = {
  input: CreateUserProgramsFromAdminInput;
};


export type MutationJoinPublicProgramArgs = {
  joinPublicProgramInput: CreateUserProgramInput;
};


export type MutationLeaveProgramFromAdminArgs = {
  leaveProgramFromAdminInput: DeleteUserProgramFromAdminInput;
};


export type MutationLoginArgs = {
  input: LogUserInDto;
};


export type MutationLoginWithAppleArgs = {
  input: LogUserInWithAppleDto;
};


export type MutationLoginWithGoogleArgs = {
  input: LogUserInWithGoogleDto;
};


export type MutationLoginWithImpersonationArgs = {
  input: ImpersonateUserDto;
};


export type MutationLoginWithMagicTokenArgs = {
  input: MagicallyLogUserInDto;
};


export type MutationLoginWithStoreTransactionIdArgs = {
  input: LogUserInWithTransactionIdDto;
};


export type MutationOrderWorkoutCircuitsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenDto;
};


export type MutationRegisterArgs = {
  input: RegisterUserDto;
};


export type MutationRegisterBulkArgs = {
  input: Array<RegisterUserBulkDto>;
};


export type MutationRegisterWithAppleArgs = {
  input: RegisterUserWithAppleDto;
};


export type MutationRegisterWithGoogleArgs = {
  input: RegisterUserWithGoogleDto;
};


export type MutationRemoveInternalUserSubscriptionFromAdminArgs = {
  userSubscriptionId: Scalars['String'];
};


export type MutationRemoveSelfUserPinCodeArgs = {
  resetPinCodeToken: Scalars['String'];
};


export type MutationRemoveUserPinCodeFromAdminArgs = {
  userId: Scalars['String'];
};


export type MutationRequestMagicLoginLinkFromEmailArgs = {
  RequestUserMagicLoginTokenType: RequestUserMagicLoginTokenType;
};


export type MutationRequestUserPasswordResetArgs = {
  requestUserPasswordResetType: RequestUserPasswordResetType;
};


export type MutationRequestUserPasswordResetLinkArgs = {
  email: Scalars['String'];
};


export type MutationResetProgramProgressionArgs = {
  programId: Scalars['String'];
};


export type MutationResetUserSwappedExercisesArgs = {
  workoutId: Scalars['String'];
};


export type MutationResetWeekProgressionArgs = {
  programWeekId: Scalars['String'];
};


export type MutationRestoreArticlesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRestoreExercisesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRestoreRecipesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationStripeCancelSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};


export type MutationStripeCreateOrScheduleSubscriptionArgs = {
  stripeCreateOrScheduleSubscriptionDto: StripeCreateOrScheduleSubscriptionDto;
};


export type MutationStripeDeleteSubscriptionScheduleArgs = {
  subscriptionScheduleId: Scalars['String'];
};


export type MutationStripeDetachPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationStripeReactivateSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};


export type MutationStripeSetDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationStripeUpdateSubscriptionArgs = {
  stripeUpdateSubscriptionDto: StripeUpdateSubscriptionDto;
  subscriptionId: Scalars['String'];
};


export type MutationStripeUpdateSubscriptionScheduleArgs = {
  stripeUpdateSubscriptionScheduleDto: StripeUpdateSubscriptionScheduleDto;
  subscriptionScheduleId: Scalars['String'];
};


export type MutationSwapUserExerciseArgs = {
  input: SwapUserExerciseType;
};


export type MutationTransferUserAccountArgs = {
  newAccount: Scalars['String'];
  oldAccount: Scalars['String'];
  options: TransferUserAccountCommandOptionsType;
};


export type MutationUpdateArticleArgs = {
  id: Scalars['String'];
  input: UpdateArticleDto;
};


export type MutationUpdateArticleCategoryArgs = {
  articleCategoryId: Scalars['String'];
  updateArticleCategoryInput: UpdateArticleCategoryInput;
};


export type MutationUpdateArticleGroupArgs = {
  id: Scalars['String'];
  input: UpdateArticleGroupInput;
};


export type MutationUpdateEmailArgs = {
  input: UpdateUserEmailDto;
};


export type MutationUpdateEquipmentArgs = {
  equipmentId: Scalars['String'];
  updateEquipmentInput: UpdateEquipmentInput;
};


export type MutationUpdateExerciseArgs = {
  id: Scalars['String'];
  input: UpdateExerciseInput;
};


export type MutationUpdateExerciseAlternativesGroupArgs = {
  exerciseAlternativesGroupId: Scalars['String'];
  updateExerciseAlternativesGroupInput: UpdateExerciseAlternativesGroupInput;
};


export type MutationUpdateIngredientArgs = {
  id: Scalars['String'];
  updateIngredientInput: UpdateIngredientInput;
};


export type MutationUpdateIngredientCategoryArgs = {
  ingredientCategoryId: Scalars['String'];
  updateIngredientCategoryInput: UpdateIngredientCategoryInput;
};


export type MutationUpdateInternalUserSubscriptionFromAdminArgs = {
  updateUserSubscriptionInput: UpdateUserSubscriptionInput;
  userSubscriptionId: Scalars['String'];
};


export type MutationUpdateMeasurementHistoryArgs = {
  input: UpdateMeasurementHistoryInput;
};


export type MutationUpdateMuscleGroupArgs = {
  id: Scalars['String'];
  updateMuscleGroupInput: UpdateMuscleGroupInput;
};


export type MutationUpdateObjectiveArgs = {
  objectiveId: Scalars['String'];
  updateObjectiveInput: UpdateObjectiveInput;
};


export type MutationUpdateProgramArgs = {
  programId: Scalars['String'];
  updateProgramInput: UpdateProgramInput;
};


export type MutationUpdateProgramProgressionArgs = {
  input: UpdateProgramProgressionInput;
};


export type MutationUpdateProgramTagArgs = {
  input: UpdateProgramTagInput;
  programTagId: Scalars['String'];
};


export type MutationUpdateProgramVariationGroupArgs = {
  input: UpdateProgramVariationGroupInput;
  programVariationGroupId: Scalars['String'];
};


export type MutationUpdateRecipeArgs = {
  id: Scalars['String'];
  input: UpdateRecipeDto;
};


export type MutationUpdateRecipeListArgs = {
  recipeListId: Scalars['String'];
  updateRecipeListInput: UpdateRecipeListInput;
};


export type MutationUpdateRecipeTagArgs = {
  recipeTagId: Scalars['String'];
  updateRecipeTagInput: UpdateRecipeTagInput;
};


export type MutationUpdateRestDayArgs = {
  id: Scalars['String'];
  input: UpdateRestDayDto;
};


export type MutationUpdateSelfUserArgs = {
  input: UpdateSelfUserDto;
};


export type MutationUpdateTargetedAreaArgs = {
  id: Scalars['String'];
  updateTargetedAreaInput: UpdateTargetedAreaInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsDto;
};


export type MutationUpdateWeekProgressionArgs = {
  input: UpdateWeekProgressionInput;
};


export type MutationUpdateWorkoutArgs = {
  id: Scalars['String'];
  input: UpdateWorkoutInput;
};


export type MutationUpdateWorkoutCategoryArgs = {
  id: Scalars['String'];
  updateWorkoutCategoryInput: UpdateWorkoutCategoryInput;
};


export type MutationValidateAppleReceiptArgs = {
  input: ValidateAppleReceiptDto;
};


export type MutationValidateGoogleReceiptArgs = {
  input: ValidateGoogleReceiptDto;
};


export type MutationVimeoImportVideosArgs = {
  videoIds: Array<Scalars['String']>;
};

/** Used to filter a numerical value */
export type NumericFilterType = {
  /** Maximum value of the parameter */
  eq?: InputMaybe<Scalars['Float']>;
  /** Must be greater than parameter */
  gt?: InputMaybe<Scalars['Float']>;
  /** Must be greater or equal than parameter */
  gte?: InputMaybe<Scalars['Float']>;
  /** Maximum value of the parameter */
  in?: InputMaybe<Array<Scalars['Float']>>;
  /** Must be less than parameter */
  lt?: InputMaybe<Scalars['Float']>;
  /** Must be less or equal than parameter */
  lte?: InputMaybe<Scalars['Float']>;
  /** Maximum value of the parameter */
  not?: InputMaybe<Scalars['Float']>;
  /** Maximum value of the parameter */
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

/** Workout objective composed of weeks */
export type Objective = {
  __typename?: 'Objective';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  translations: ObjectiveI18N;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter objectives */
export type ObjectiveFiltersType = {
  /** Filter program by name */
  name?: InputMaybe<Scalars['String']>;
};

/** Translated fields of objectives */
export type ObjectiveI18N = {
  __typename?: 'ObjectiveI18N';
  name: Scalars['String'];
};

/** Used to order objectives */
export type ObjectiveOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ObjectiveOrderProperties>;
};

/** Objective properties that can be ordered */
export enum ObjectiveOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Order by */
export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Platform that issued the transaction */
export enum PaymentPlatform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

/** Workout program composed of weeks */
export type Program = {
  __typename?: 'Program';
  activeUsers?: Maybe<Scalars['Float']>;
  author: UserType;
  /** In minutes, this is not computed but manually contributed */
  averageWorkoutDuration?: Maybe<Scalars['Float']>;
  completionPercentage?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  equipments?: Maybe<Array<ProgramEquipments>>;
  fullScreenImage?: Maybe<MediaType>;
  gym: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  home: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  /** Always true if the program is public (not hidden). If not, checks if the user has access to it. */
  isAvailableForUser: Scalars['Boolean'];
  level: Scalars['Float'];
  maxDays?: Maybe<Scalars['Float']>;
  maxDuration?: Maybe<Scalars['Float']>;
  minDays?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  /** Number of people having completed the program */
  numberOfPeopleWhoCompleted?: Maybe<Scalars['Float']>;
  objectives?: Maybe<Array<ProgramObjectives>>;
  programVariationGroup?: Maybe<ProgramVariationGroup>;
  programVariationGroupId?: Maybe<Scalars['String']>;
  publicationStatus: Scalars['String'];
  /** Array of medias representing profile pictures of users that currently have this program as active */
  randomUsersPictures?: Maybe<Array<UserUpload>>;
  /** Array of medias representing profile pictures of users that have access to this program */
  randomUsersWithAvailabilityPictures?: Maybe<Array<UserUpload>>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<ProgramTags>>;
  targetedAreas?: Maybe<Array<WorkoutTargetedAreas>>;
  translations: ProgramI18N;
  type?: Maybe<ProgramType>;
  updatedAt: Scalars['DateTime'];
  usersAvailabilityCount?: Maybe<Scalars['Float']>;
  weeks: Array<ProgramWeeks>;
};

/** Stats & info regarding a program completion */
export type ProgramCompletionStatistics = {
  __typename?: 'ProgramCompletionStatistics';
  numberOfPeopleWhoCompleted?: Maybe<Scalars['Float']>;
  numberOfTimesCompleted?: Maybe<Scalars['Float']>;
  peopleWhoCompletedRandomPictures?: Maybe<Array<UserUpload>>;
  program: Program;
};

/** Used to get distinct programs */
export type ProgramDistinctType = {
  /** Distinct programs by programVariationGroupId */
  programVariationGroupId?: InputMaybe<Scalars['Boolean']>;
};

/** What kind of equipments are required to follow a program */
export enum ProgramEquipment {
  FullGym = 'FULL_GYM',
  Home = 'HOME',
  None = 'NONE'
}

export type ProgramEquipments = {
  __typename?: 'ProgramEquipments';
  equipment: Equipment;
  equipmentId: Scalars['ID'];
  id: Scalars['ID'];
};

/** Used to filter programs */
export type ProgramFiltersType = {
  /** Filter programs by authors */
  authorIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter only completed programs. */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** Filter program by the id of one of its equipments */
  equipmentIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter program by excluding some of their ids */
  excludedProgramIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter only gym programs */
  gym?: InputMaybe<Scalars['Boolean']>;
  /** Filter program by whenever it is hidden or not */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** Filter only home programs */
  home?: InputMaybe<Scalars['Boolean']>;
  /** Filter only completed programs. Meaning their progression percentage is < 100. */
  inProgress?: InputMaybe<Scalars['Boolean']>;
  /** Filter program by whenever it is joined or not */
  joined?: InputMaybe<Scalars['Boolean']>;
  /** Filter program by program levels */
  levels?: InputMaybe<Array<ProgramLevel>>;
  /** Filter program by maximum workout duration */
  maxWorkoutDuration?: InputMaybe<Scalars['Float']>;
  /** Filter program by minimum workout duration */
  minWorkoutDuration?: InputMaybe<Scalars['Float']>;
  /** Filter program by objectives ids */
  objectiveIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter only ongoing programs. Meaning their startDate < now and endDate > now */
  ongoing?: InputMaybe<Scalars['Boolean']>;
  /** Filter program by their ids */
  programIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter program by programVariationGroupId */
  programVariationGroupId?: InputMaybe<Scalars['String']>;
  /** Filter programs by publication status */
  publicationStatus?: InputMaybe<PublicationStatus>;
  /** Filter programs by program tags */
  tagsIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter program by title */
  title?: InputMaybe<Scalars['String']>;
  /** Filter programs by program types */
  type?: InputMaybe<ProgramType>;
  /** Filter program with no equipments associated */
  withoutEquipments?: InputMaybe<Scalars['Boolean']>;
};

/** Translated fields of programs */
export type ProgramI18N = {
  __typename?: 'ProgramI18N';
  description?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** The different levels of program */
export enum ProgramLevel {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE'
}

export type ProgramObjectives = {
  __typename?: 'ProgramObjectives';
  id: Scalars['ID'];
  objective: Objective;
  objectiveId: Scalars['ID'];
};

/** Used to order programs */
export type ProgramOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ProgramOrderProperties>;
};

/** Program properties that can be ordered */
export enum ProgramOrderProperties {
  CreatedAt = 'createdAt',
  Level = 'level',
  MaxDuration = 'maxDuration',
  MinDuration = 'minDuration',
  MostPopular = 'mostPopular',
  UpdatedAt = 'updatedAt',
  UserProgression = 'userProgression'
}

/** ProgramProgression */
export type ProgramProgression = {
  __typename?: 'ProgramProgression';
  completionPercentage: Scalars['Float'];
  currentProgramWeekId: Scalars['ID'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  program: Program;
  programId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  /** Weeks progression associated to this program progression. Includes the same week multiple times if it has been reset. */
  weekProgressions: Array<WeekProgression>;
};

export type ProgramProgressionFiltersType = {
  programId?: InputMaybe<Scalars['String']>;
  programProgressionId?: InputMaybe<Scalars['String']>;
};

/** Used to order programProgressions */
export type ProgramProgressionOrderByType = {
  order: OrderByEnum;
  property: ProgramProgressionOrderProperties;
};

export enum ProgramProgressionOrderProperties {
  CompletionPercentage = 'completionPercentage',
  CreatedAt = 'createdAt',
  FinishedAt = 'finishedAt',
  UpdatedAt = 'updatedAt'
}

/** ProgramProgressionSummary */
export type ProgramProgressionSummary = {
  __typename?: 'ProgramProgressionSummary';
  completedWeeks: Scalars['Float'];
  completionPercentage: Scalars['Float'];
  currentProgramWeekId: Scalars['ID'];
  programId: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['String'];
  weeks: Scalars['Float'];
  weeksCompletionSummary: Array<WeekProgression>;
};

/** Program tag */
export type ProgramTag = {
  __typename?: 'ProgramTag';
  id: Scalars['ID'];
  internalName: Scalars['String'];
  position: Scalars['Float'];
  slug: Scalars['String'];
  translations: ProgramTagI18N;
};

/** Translated fields of programTags */
export type ProgramTagI18N = {
  __typename?: 'ProgramTagI18N';
  name: Scalars['String'];
};

/** Used to order programTags */
export type ProgramTagOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ProgramTagOrderProperties>;
};

/** ProgramTag properties that can be ordered */
export enum ProgramTagOrderProperties {
  CreatedAt = 'createdAt',
  Position = 'position',
  UpdatedAt = 'updatedAt'
}

export type ProgramTags = {
  __typename?: 'ProgramTags';
  id: Scalars['ID'];
  tag: ProgramTag;
};

/** Used to filter programTags */
export type ProgramTagsFiltersType = {
  /** Filter programTags by internalName */
  internalName?: InputMaybe<Scalars['String']>;
};

/** The different types of program */
export enum ProgramType {
  Challenge = 'CHALLENGE',
  Personal = 'PERSONAL',
  Regular = 'REGULAR'
}

/** Program group */
export type ProgramVariationGroup = {
  __typename?: 'ProgramVariationGroup';
  id: Scalars['ID'];
  internalName: Scalars['String'];
  programs: Array<Program>;
};

/** Used to filter programVariationGroups */
export type ProgramVariationGroupFiltersType = {
  /** Filter programVariationGroups by internalName */
  internalName?: InputMaybe<Scalars['String']>;
};

/** Individual week attached to a program */
export type ProgramWeek = {
  __typename?: 'ProgramWeek';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items?: Maybe<Array<ProgramWeekItem>>;
  updatedAt: Scalars['DateTime'];
};

/** Items attached to a program's week */
export type ProgramWeekItem = {
  __typename?: 'ProgramWeekItem';
  id: Scalars['ID'];
  position: Scalars['Float'];
  restDay?: Maybe<RestDay>;
  workout?: Maybe<Workout>;
};

/** Association between program and programWeeks */
export type ProgramWeeks = {
  __typename?: 'ProgramWeeks';
  id: Scalars['ID'];
  position: Scalars['Float'];
  programWeek: ProgramWeek;
};

/** The different average durations of a program's workouts */
export enum ProgramWorkoutDuration {
  Long = 'LONG',
  Medium = 'MEDIUM',
  Short = 'SHORT'
}

export type ProgressPicture = {
  __typename?: 'ProgressPicture';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  status: UserUploadStatuses;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

/** Used to order progressPictures */
export type ProgressPictureOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<ProgressPictureOrderProperties>;
};

/** progressPicture properties that can be ordered */
export enum ProgressPictureOrderProperties {
  CreatedAt = 'createdAt',
  Date = 'date',
  UpdatedAt = 'updatedAt'
}

/**
 *
 *         Different status of publication for the content.
 *
 *         * `published`: visible by everyone
 *         * `unpublished`: visible by no one
 *         * `draft`: visible by elevated users
 *
 */
export enum PublicationStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type Query = {
  __typename?: 'Query';
  article: Article;
  articleCategories: FindArticleCategoryResultType;
  articleCategory: ArticleCategory;
  articleFavorites: FindArticleFavoriteResultType;
  articleGroup: ArticleGroup;
  articleGroups: FindArticleGroupResultType;
  articles: FindArticleResultType;
  closestTimeLimitedProgram: Program;
  coachingClients: Array<CoachingClientType>;
  coachingClientsOverview: Array<CoachingClientType>;
  collages: FindCollageResultType;
  currentProgramProgressionSummary: ProgramProgressionSummary;
  currentSubscription: FindUserCurrentSubscriptionResultType;
  equipment: Equipment;
  equipments: FindEquipmentResultType;
  exercise: Exercise;
  exerciseAlternativesGroup: ExerciseAlternativesGroup;
  exerciseAlternativesGroups: FindExerciseAlternativesGroupResultType;
  exerciseHistories: FindExerciseHistoryResultType;
  exerciseHistoriesSummary: ExerciseHistoriesSummary;
  exercises: FindExerciseResultType;
  fixedWorkoutList: FindWorkoutResultType;
  influencers: FindUserResultType;
  ingredient: Ingredient;
  ingredientCategories: FindIngredientCategoryResultType;
  ingredientCategory: IngredientCategory;
  ingredients: FindIngredientResultType;
  measurementHistories: FindMeasurementHistoryResultType;
  medias: FindMediaResultType;
  metadata: FindMetadataResultType;
  mostRecentWorkoutHistory: Array<WorkoutHistory>;
  /** Returns the most recent workout history item for every workout inside a week that the user completed.Please note that it checks for potential progression resets, contrary to mostRecentWorkoutHistory(). */
  mostRecentWorkoutHistoryInWeek: Array<WorkoutHistory>;
  muscleGroup: MuscleGroup;
  muscleGroups: FindMuscleGroupResultType;
  objective: Objective;
  objectives: FindObjectiveResultType;
  program: Program;
  programCompletionSummary: ProgramCompletionStatistics;
  programProgressionSummary: ProgramProgressionSummary;
  programProgressionSummaryFromUserId: ProgramProgressionSummary;
  programProgressions: FindProgramProgressionResultType;
  programTag: ProgramTag;
  programTags: FindProgramTagResultType;
  programVariationGroup: ProgramVariationGroup;
  programVariationGroups: FindProgramVariationGroupResultType;
  programs: FindProgramResultType;
  progressPictures: FindProgressPictureResultType;
  recipe: Recipe;
  recipeFavorites: FindRecipeFavoriteResultType;
  recipeList: RecipeList;
  recipeListItems: FindRecipeListItemsResultType;
  recipeLists: FindRecipeListResultType;
  recipeTag: RecipeTag;
  recipeTags: FindRecipeTagResultType;
  recipes: FindRecipeResultType;
  recommendedProgram: Program;
  restDay: RestDay;
  restDays: FindRestDayResultType;
  selfUser: UserType;
  stats: Stats;
  stripeCustomer: StripeCustomerType;
  stripePaymentMethods: Array<StripePaymentMethodType>;
  /** Lets you preview a subscription invoice.  */
  stripePreviewUpcomingSubscriptionInvoice: StripeInvoicePreviewType;
  stripePricesFromProduct: Array<StripePriceType>;
  stripePromotionCodeByCustomerFacingCode: StripePromotionCodeType;
  stripeSubscription: StripeSubscriptionType;
  stripeSubscriptionSchedule: StripeSubscriptionScheduleType;
  stripeSubscriptionSchedules: Array<StripeSubscriptionScheduleType>;
  stripeUpcomingInvoice: StripeInvoiceType;
  targetedArea: TargetedArea;
  targetedAreas: FindTargetedAreaResultType;
  user: UserType;
  userConnectionMethods: FindUserConnectionMethodsType;
  userEvents: FindUserEventResultType;
  userFirstName: FindUserFirstNameType;
  userJoinedProgramsFromAdmin: FindProgramResultType;
  userStats: UserStats;
  userSubscription: UserSubscription;
  userSubscriptions: FindUserSubscriptionResultType;
  userSwappedExercises: FindSwappedExercisesResultType;
  users: FindUserResultType;
  weekCompletionSummary: WeekCompletionSummary;
  workout: Workout;
  workoutCategories: FindWorkoutCategoryResultType;
  workoutCategory: WorkoutCategory;
  workoutCompletionSummary: WorkoutHistory;
  workoutFavorites: FindWorkoutFavoriteResultType;
  workoutHistories: FindWorkoutHistoryResultType;
  workoutHistory: WorkoutHistory;
  workoutList: WorkoutList;
  workouts: FindWorkoutResultType;
};


export type QueryArticleArgs = {
  id: Scalars['String'];
};


export type QueryArticleCategoriesArgs = {
  findArticleCategoryType: FindArticleCategory;
};


export type QueryArticleCategoryArgs = {
  id: Scalars['String'];
};


export type QueryArticleFavoritesArgs = {
  findArticleFavoriteType: FindArticleFavorite;
};


export type QueryArticleGroupArgs = {
  id: Scalars['String'];
};


export type QueryArticleGroupsArgs = {
  findArticleGroupType: FindArticleGroup;
};


export type QueryArticlesArgs = {
  findArticleType: FindArticle;
};


export type QueryCollagesArgs = {
  findCollageType: FindCollage;
};


export type QueryEquipmentArgs = {
  id: Scalars['String'];
};


export type QueryEquipmentsArgs = {
  findEquipmentType: FindEquipment;
};


export type QueryExerciseArgs = {
  id: Scalars['String'];
};


export type QueryExerciseAlternativesGroupArgs = {
  id: Scalars['String'];
};


export type QueryExerciseAlternativesGroupsArgs = {
  findExerciseAlternativesGroupType: FindExerciseAlternativesGroup;
};


export type QueryExerciseHistoriesArgs = {
  findExerciseHistoryType: FindExerciseHistory;
};


export type QueryExerciseHistoriesSummaryArgs = {
  findExerciseHistoriesSummaryType: ExerciseHistoriesSummaryType;
};


export type QueryExercisesArgs = {
  findExerciseType: FindExercise;
};


export type QueryFixedWorkoutListArgs = {
  slug: Scalars['String'];
};


export type QueryInfluencersArgs = {
  findUserType: FindUser;
};


export type QueryIngredientArgs = {
  id: Scalars['String'];
};


export type QueryIngredientCategoriesArgs = {
  findIngredientCategoryType: FindIngredientCategory;
};


export type QueryIngredientCategoryArgs = {
  id: Scalars['String'];
};


export type QueryIngredientsArgs = {
  findIngredientType: FindIngredient;
};


export type QueryMeasurementHistoriesArgs = {
  findMeasurementHistoryType: FindMeasurementHistory;
};


export type QueryMediasArgs = {
  findMediaType: FindMedia;
};


export type QueryMetadataArgs = {
  slug: Scalars['String'];
};


export type QueryMostRecentWorkoutHistoryArgs = {
  findMostRecentHistory: FindMostRecentWorkoutHistory;
};


export type QueryMostRecentWorkoutHistoryInWeekArgs = {
  findMostRecentHistory: FindMostRecentWorkoutHistoryInWeek;
};


export type QueryMuscleGroupArgs = {
  id: Scalars['String'];
};


export type QueryMuscleGroupsArgs = {
  findMuscleGroupType: FindMuscleGroup;
};


export type QueryObjectiveArgs = {
  id: Scalars['String'];
};


export type QueryObjectivesArgs = {
  findObjectiveType: FindObjective;
};


export type QueryProgramArgs = {
  id: Scalars['String'];
};


export type QueryProgramCompletionSummaryArgs = {
  programId: Scalars['String'];
};


export type QueryProgramProgressionSummaryArgs = {
  programId: Scalars['String'];
};


export type QueryProgramProgressionSummaryFromUserIdArgs = {
  programId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryProgramProgressionsArgs = {
  findProgramProgressionsType: FindProgramProgression;
};


export type QueryProgramTagArgs = {
  id: Scalars['String'];
};


export type QueryProgramTagsArgs = {
  findProgramTagType: FindProgramTag;
};


export type QueryProgramVariationGroupArgs = {
  id: Scalars['String'];
};


export type QueryProgramVariationGroupsArgs = {
  findProgramVariationGroupType: FindProgramVariationGroup;
};


export type QueryProgramsArgs = {
  findProgramType: FindProgram;
};


export type QueryProgressPicturesArgs = {
  findProgressPictureType: FindProgressPicture;
};


export type QueryRecipeArgs = {
  id: Scalars['String'];
};


export type QueryRecipeFavoritesArgs = {
  findRecipeFavoriteType: FindRecipeFavorite;
};


export type QueryRecipeListArgs = {
  findUniqueRecipeListType: FindUniqueRecipeList;
};


export type QueryRecipeListItemsArgs = {
  findRecipeListItemType: FindRecipeListItems;
};


export type QueryRecipeListsArgs = {
  findRecipeListType: FindRecipeList;
};


export type QueryRecipeTagArgs = {
  id: Scalars['String'];
};


export type QueryRecipeTagsArgs = {
  findRecipeTagType: FindRecipeTag;
};


export type QueryRecipesArgs = {
  findRecipeType: FindRecipe;
};


export type QueryRecommendedProgramArgs = {
  findRecommendedProgramType: FindRecommendedProgram;
};


export type QueryRestDayArgs = {
  id: Scalars['String'];
};


export type QueryRestDaysArgs = {
  findRestDayType: FindRestDay;
};


export type QueryStatsArgs = {
  input: FindStats;
};


export type QueryStripePaymentMethodsArgs = {
  type: Scalars['String'];
};


export type QueryStripePreviewUpcomingSubscriptionInvoiceArgs = {
  stripePreviewUpcomingInvoiceDto: StripePreviewUpcomingInvoiceDto;
};


export type QueryStripePricesFromProductArgs = {
  currency: Scalars['String'];
  productId: Scalars['String'];
};


export type QueryStripePromotionCodeByCustomerFacingCodeArgs = {
  customerFacingCode: Scalars['String'];
};


export type QueryStripeSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};


export type QueryStripeSubscriptionScheduleArgs = {
  subscriptionScheduleId: Scalars['String'];
};


export type QueryStripeUpcomingInvoiceArgs = {
  subscriptionId?: InputMaybe<Scalars['String']>;
  subscriptionScheduleId?: InputMaybe<Scalars['String']>;
};


export type QueryTargetedAreaArgs = {
  findTargetedAreaType: FindUniqueTargetedArea;
};


export type QueryTargetedAreasArgs = {
  findTargetedAreaType: FindTargetedArea;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserConnectionMethodsArgs = {
  email: Scalars['String'];
};


export type QueryUserEventsArgs = {
  findUserEventType: FindUserEvent;
};


export type QueryUserFirstNameArgs = {
  email: Scalars['String'];
};


export type QueryUserJoinedProgramsFromAdminArgs = {
  findUserJoinedProgramType: FindUserJoinedProgram;
};


export type QueryUserStatsArgs = {
  input: FindUsersStats;
};


export type QueryUserSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryUserSubscriptionsArgs = {
  findUserSubscriptionType: FindUserSubscription;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryUserSwappedExercisesArgs = {
  workoutId: Scalars['String'];
};


export type QueryUsersArgs = {
  findUserType: FindUser;
};


export type QueryWeekCompletionSummaryArgs = {
  programWeekId: Scalars['String'];
};


export type QueryWorkoutArgs = {
  id: Scalars['String'];
};


export type QueryWorkoutCategoriesArgs = {
  findWorkoutCategoryType: FindWorkoutCategory;
};


export type QueryWorkoutCategoryArgs = {
  findWorkoutCategoryType: FindUniqueWorkoutCategory;
};


export type QueryWorkoutCompletionSummaryArgs = {
  id: Scalars['String'];
};


export type QueryWorkoutFavoritesArgs = {
  findWorkoutFavoriteType: FindWorkoutFavorite;
};


export type QueryWorkoutHistoriesArgs = {
  findWorkoutHistoryType: FindWorkoutHistory;
};


export type QueryWorkoutHistoryArgs = {
  workoutHistoryId: Scalars['String'];
};


export type QueryWorkoutListArgs = {
  findWorkoutListType: FindUniqueWorkoutList;
};


export type QueryWorkoutsArgs = {
  findWorkoutType: FindWorkout;
};

/** Blog recipes */
export type Recipe = {
  __typename?: 'Recipe';
  author: UserType;
  calories: Scalars['Float'];
  carbs: Scalars['Float'];
  cookTime: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  fat: Scalars['Float'];
  fullScreenImage?: Maybe<MediaType>;
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  ingredients?: Maybe<Array<RecipeIngredients>>;
  lists?: Maybe<Array<RecipeLists>>;
  preparationTime: Scalars['Float'];
  proteins: Scalars['Float'];
  publicationStatus: PublicationStatus;
  servings: Scalars['Float'];
  tags?: Maybe<Array<RecipeTags>>;
  translations: RecipeI18N;
  updatedAt: Scalars['DateTime'];
};

/** Recipe favorite */
export type RecipeFavorite = {
  __typename?: 'RecipeFavorite';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  recipe?: Maybe<Recipe>;
  recipeId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Used to order recipe favorites */
export type RecipeFavoriteOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<RecipeFavoriteOrderProperties>;
};

/** Recipe Favorite properties that can be ordered */
export enum RecipeFavoriteOrderProperties {
  CreatedAt = 'createdAt'
}

/** Used to filter recipes */
export type RecipeFiltersType = {
  /** Filter recipes by author */
  authorId?: InputMaybe<Scalars['String']>;
  /** Filter deleted recipes */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Filter recipes by maximum calories */
  maxCalories?: InputMaybe<Scalars['Float']>;
  /** Filter recipes by maximum cook time */
  maxCookTime?: InputMaybe<Scalars['Float']>;
  /** Filter recipes by maximum preparation time */
  maxPreparationTime?: InputMaybe<Scalars['Float']>;
  /** Filter recipes by minimum calories */
  minCalories?: InputMaybe<Scalars['Float']>;
  /** Filter recipes by minimum cook time */
  minCookTime?: InputMaybe<Scalars['Float']>;
  /** Filter recipes by minimum preparation time */
  minPreparationTime?: InputMaybe<Scalars['Float']>;
  /** Filter recipes by publication status */
  publicationStatus?: InputMaybe<PublicationStatus>;
  /** Filter recipes by recipeLists slugs */
  recipeListsSlugs?: InputMaybe<Array<Scalars['String']>>;
  /** Filter recipes by recipeTags ids */
  recipeTagsIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter recipes by name */
  title?: InputMaybe<Scalars['String']>;
};

/** Translated fields of recipes */
export type RecipeI18N = {
  __typename?: 'RecipeI18N';
  content: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Recipe ingredients association */
export type RecipeIngredients = {
  __typename?: 'RecipeIngredients';
  ingredient: Ingredient;
  quantity?: Maybe<Scalars['Float']>;
  recipe?: Maybe<Recipe>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** List of manually ordered recipes (eg: dinner, lunch, breakfast...) */
export type RecipeList = {
  __typename?: 'RecipeList';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Array<RecipeListItems>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Update a recipe position */
export type RecipeListItemInput = {
  position: Scalars['Float'];
  recipeId: Scalars['ID'];
};

/** Used to order recipeListItems */
export type RecipeListItemOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<RecipeListItemOrderProperties>;
};

/** RecipeListItem properties that can be ordered */
export enum RecipeListItemOrderProperties {
  CreatedAt = 'createdAt',
  Position = 'position',
  UpdatedAt = 'updatedAt'
}

export type RecipeListItems = {
  __typename?: 'RecipeListItems';
  id: Scalars['ID'];
  list: RecipeList;
  position: Scalars['Float'];
  recipe: Recipe;
};

/** Used to order recipeLists */
export type RecipeListOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<RecipeListOrderProperties>;
};

/** RecipeList properties that can be ordered */
export enum RecipeListOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Recipe lists of a recipe */
export type RecipeLists = {
  __typename?: 'RecipeLists';
  id: Scalars['String'];
  list: RecipeList;
  recipe: Recipe;
};

/** Used to order recipes */
export type RecipeOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<RecipeOrderProperties>;
};

/** Recipe properties that can be ordered */
export enum RecipeOrderProperties {
  Calories = 'calories',
  CreatedAt = 'createdAt',
  PreparationTime = 'preparationTime',
  UpdatedAt = 'updatedAt'
}

/** Multiple tags assigned to recipe */
export type RecipeTag = {
  __typename?: 'RecipeTag';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  recipes: Array<RecipeTags>;
  translations: RecipeTagI18N;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter recipeTags */
export type RecipeTagFiltersType = {
  /** Filter muscle group by name */
  title?: InputMaybe<Scalars['String']>;
};

/** Translated fields of recipeTags */
export type RecipeTagI18N = {
  __typename?: 'RecipeTagI18N';
  title: Scalars['String'];
};

/** Used to order recipeTags */
export type RecipeTagOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<RecipeTagOrderProperties>;
};

/** RecipeTag properties that can be ordered */
export enum RecipeTagOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type RecipeTags = {
  __typename?: 'RecipeTags';
  id: Scalars['ID'];
  recipe: Recipe;
  tag: RecipeTag;
};

/** Recovery workout */
export type RecoveryWorkout = {
  __typename?: 'RecoveryWorkout';
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type RefreshTokenDto = {
  refreshToken: Scalars['String'];
};

export type RegisterUserBulkDto = {
  activeProgramId?: InputMaybe<Scalars['String']>;
  /** Omit this to create a claimable account */
  credentials?: InputMaybe<RegisterUserCredentialsDto>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  onboardingMatchingAnswers?: InputMaybe<Scalars['String']>;
  webFunnelAnalytics?: InputMaybe<Scalars['String']>;
};

export type RegisterUserCredentialsDto = {
  password: Scalars['String'];
};

export type RegisterUserDto = {
  activeProgramId?: InputMaybe<Scalars['String']>;
  credentials: RegisterUserCredentialsDto;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  onboardingMatchingAnswers?: InputMaybe<Scalars['String']>;
  webFunnelAnalytics?: InputMaybe<Scalars['String']>;
};

export type RegisterUserWithAppleDto = {
  activeProgramId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  identityToken?: InputMaybe<Scalars['String']>;
  onboardingMatchingAnswers?: InputMaybe<Scalars['String']>;
  webFunnelAnalytics?: InputMaybe<Scalars['String']>;
};

export type RegisterUserWithGoogleDto = {
  activeProgramId?: InputMaybe<Scalars['String']>;
  idToken: Scalars['String'];
  onboardingMatchingAnswers?: InputMaybe<Scalars['String']>;
  webFunnelAnalytics?: InputMaybe<Scalars['String']>;
};

export type RemoveCoachesFromUserDto = {
  coachIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

/** Remove user's PIN response */
export type RemoveUserPinCodeResultType = {
  __typename?: 'RemoveUserPinCodeResultType';
  requested: Scalars['Boolean'];
};

/** Remove user subscription type */
export type RemoveUserSubscriptionType = {
  __typename?: 'RemoveUserSubscriptionType';
  userSubscriptionId: Scalars['ID'];
};

/** Result of requesting the deletion of an account */
export type RequestAccountDeletionResultType = {
  __typename?: 'RequestAccountDeletionResultType';
  requested: Scalars['Boolean'];
};

/** Result of requesting the magic connection token */
export type RequestMagicLoginTokenResultType = {
  __typename?: 'RequestMagicLoginTokenResultType';
  link: Scalars['String'];
  requested: Scalars['Boolean'];
  token: Scalars['String'];
};

/** Result of requesting the reset of a user's credential */
export type RequestUserCredentialResetResultType = {
  __typename?: 'RequestUserCredentialResetResultType';
  requested: Scalars['Boolean'];
};

/** Request a user magic login token */
export type RequestUserMagicLoginTokenType = {
  email: Scalars['String'];
  withPasswordChange: Scalars['Boolean'];
};

/** Request the reset of a user's password */
export type RequestUserPasswordResetType = {
  email: Scalars['String'];
};

/** Result of requesting the reset of a user's password */
export type RequestUserResetPasswordLinkResultType = {
  __typename?: 'RequestUserResetPasswordLinkResultType';
  link: Scalars['String'];
};

/** Rest day that can be attached to a program week */
export type RestDay = {
  __typename?: 'RestDay';
  createdAt: Scalars['DateTime'];
  fullScreenImage?: Maybe<MediaType>;
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  translations?: Maybe<RestDayI18n>;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter restDays */
export type RestDayFiltersType = {
  /** Filter rest day by internal Name */
  internalName?: InputMaybe<Scalars['String']>;
};

/** Translated fields of rest days */
export type RestDayI18n = {
  __typename?: 'RestDayI18n';
  internalName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  quoteAuthor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Used to order restdays */
export type RestDayOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<RestDayOrderProperties>;
};

/** Workout properties that can be ordered */
export enum RestDayOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type SetHistory = {
  __typename?: 'SetHistory';
  createdAt: Scalars['DateTime'];
  exerciseHistoryId: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Float'];
  scored?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Float']>;
};

/** Global Statistics */
export type Stats = {
  __typename?: 'Stats';
  endDate?: Maybe<Scalars['DateTime']>;
  numberOfMinutesExercised?: Maybe<Scalars['Float']>;
  numberOfTrustingUsers?: Maybe<Scalars['Float']>;
  numberOfWorkoutDone?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type StripeCardType = {
  __typename?: 'StripeCardType';
  brand: Scalars['String'];
  exp_month: Scalars['Float'];
  exp_year: Scalars['Float'];
  last4: Scalars['String'];
};

/** https://stripe.com/docs/api/coupons/object#coupon_object-duration */
export enum StripeCouponDuration {
  Forever = 'FOREVER',
  Once = 'ONCE',
  Repeating = 'REPEATING'
}

export type StripeCouponType = {
  __typename?: 'StripeCouponType';
  amount_off?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  duration?: Maybe<StripeCouponDuration>;
  duration_in_months?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  name: Scalars['String'];
  percent_off?: Maybe<Scalars['Float']>;
  valid: Scalars['Boolean'];
};

export type StripeCreateOrScheduleSubscriptionDto = {
  customerFacingPromotionCode?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
  priceId: Scalars['String'];
};

export type StripeCreateOrScheduleSubscriptionResultType = {
  __typename?: 'StripeCreateOrScheduleSubscriptionResultType';
  subscription?: Maybe<StripeSubscriptionType>;
  subscriptionSchedule?: Maybe<StripeSubscriptionScheduleType>;
};

export type StripeCustomerInvoiceSettingsType = {
  __typename?: 'StripeCustomerInvoiceSettingsType';
  default_payment_method?: Maybe<Scalars['String']>;
};

export type StripeCustomerType = {
  __typename?: 'StripeCustomerType';
  balance: Scalars['Float'];
  created: Scalars['Float'];
  currency?: Maybe<Scalars['String']>;
  defaultPaymentMethod?: Maybe<StripePaymentMethodType>;
  default_source?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoice_settings: StripeCustomerInvoiceSettingsType;
};

export type StripeDiscountType = {
  __typename?: 'StripeDiscountType';
  coupon: StripeCouponType;
  id: Scalars['String'];
  start: Scalars['Float'];
  subscription?: Maybe<Scalars['String']>;
};

export type StripeInvoiceDiscountAmountsType = {
  __typename?: 'StripeInvoiceDiscountAmountsType';
  amount: Scalars['Float'];
  discount: StripeDiscountType;
};

export type StripeInvoiceLineType = {
  __typename?: 'StripeInvoiceLineType';
  discount_amounts?: Maybe<Array<StripeInvoiceDiscountAmountsType>>;
  plan: StripePlanType;
};

export type StripeInvoiceLinesType = {
  __typename?: 'StripeInvoiceLinesType';
  data: Array<StripeInvoiceLineType>;
};

export type StripeInvoicePreviewType = {
  __typename?: 'StripeInvoicePreviewType';
  currency: Scalars['String'];
  discountedPrice?: Maybe<Scalars['Float']>;
  newPriceCost: Scalars['Float'];
  total: Scalars['Float'];
  unusedTimeCredit?: Maybe<Scalars['Float']>;
};

export type StripeInvoiceType = {
  __typename?: 'StripeInvoiceType';
  amount_due: Scalars['Float'];
  amount_paid: Scalars['Float'];
  amount_remaining: Scalars['Float'];
  created: Scalars['Float'];
  currency: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lines: StripeInvoiceLinesType;
  period_end?: Maybe<Scalars['Float']>;
  period_start?: Maybe<Scalars['Float']>;
};

export type StripePaymentMethodType = {
  __typename?: 'StripePaymentMethodType';
  card?: Maybe<StripeCardType>;
  created: Scalars['Float'];
  id: Scalars['String'];
};

export type StripePlanType = {
  __typename?: 'StripePlanType';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  id: Scalars['String'];
  interval: Scalars['String'];
  interval_count: Scalars['Float'];
};

export type StripePreviewUpcomingInvoiceDto = {
  currentSubscriptionId?: InputMaybe<Scalars['String']>;
  customerFacingPromotionCode?: InputMaybe<Scalars['String']>;
  newPrice: Scalars['String'];
};

/** https://stripe.com/docs/api/prices/object#price_object-type */
export enum StripePricePurchaseType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING'
}

export type StripePriceType = {
  __typename?: 'StripePriceType';
  active?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  recurring?: Maybe<StripeRecurringPriceType>;
  type?: Maybe<StripePricePurchaseType>;
  unit_amount?: Maybe<Scalars['Float']>;
  unit_amount_decimal?: Maybe<Scalars['String']>;
};

export type StripePromotionCodeType = {
  __typename?: 'StripePromotionCodeType';
  code: Scalars['String'];
  coupon: StripeCouponType;
  created: Scalars['Float'];
  expires_at?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
};

/** https://stripe.com/docs/api/prices/object#price_object-recurring-aggregate_usage */
export enum StripeRecurringPriceInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type StripeRecurringPriceType = {
  __typename?: 'StripeRecurringPriceType';
  interval: StripeRecurringPriceInterval;
  interval_count: Scalars['Float'];
};

export type StripeSubscriptionItemType = {
  __typename?: 'StripeSubscriptionItemType';
  id: Scalars['String'];
  price: StripePriceType;
};

export type StripeSubscriptionItemsType = {
  __typename?: 'StripeSubscriptionItemsType';
  data: Array<StripeSubscriptionItemType>;
};

export type StripeSubscriptionScheduleCurrentPhaseType = {
  __typename?: 'StripeSubscriptionScheduleCurrentPhaseType';
  end_date: Scalars['Float'];
  start_date: Scalars['Float'];
};

export type StripeSubscriptionSchedulePhaseItem = {
  __typename?: 'StripeSubscriptionSchedulePhaseItem';
  price: StripePriceType;
};

export type StripeSubscriptionSchedulePhaseType = {
  __typename?: 'StripeSubscriptionSchedulePhaseType';
  coupon?: Maybe<StripeCouponType>;
  currency: Scalars['String'];
  default_payment_method?: Maybe<Scalars['String']>;
  end_date: Scalars['Float'];
  items: Array<StripeSubscriptionSchedulePhaseItem>;
  start_date: Scalars['Float'];
};

export type StripeSubscriptionScheduleType = {
  __typename?: 'StripeSubscriptionScheduleType';
  canceled_at?: Maybe<Scalars['Float']>;
  completed_at?: Maybe<Scalars['Float']>;
  created: Scalars['Float'];
  current_phase?: Maybe<StripeSubscriptionScheduleCurrentPhaseType>;
  id: Scalars['String'];
  phases: Array<StripeSubscriptionSchedulePhaseType>;
  released_at?: Maybe<Scalars['Float']>;
};

/** See : https://stripe.com/docs/api/subscriptions/object?lang=node#subscription_object-status */
export enum StripeSubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export type StripeSubscriptionType = {
  __typename?: 'StripeSubscriptionType';
  billing_cycle_anchor: Scalars['Float'];
  cancel_at?: Maybe<Scalars['Float']>;
  cancel_at_period_end: Scalars['Boolean'];
  canceled_at?: Maybe<Scalars['Float']>;
  created: Scalars['Float'];
  currency: Scalars['String'];
  current_period_end: Scalars['Float'];
  current_period_start: Scalars['Float'];
  defaultPaymentMethod?: Maybe<StripePaymentMethodType>;
  discount?: Maybe<StripeDiscountType>;
  ended_at?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  items: StripeSubscriptionItemsType;
  start_date: Scalars['Float'];
  status: StripeSubscriptionStatus;
};

export type StripeUpdateSubscriptionDto = {
  customerFacingPromotionCode?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  priceId?: InputMaybe<Scalars['String']>;
};

export type StripeUpdateSubscriptionScheduleDto = {
  customerFacingPromotionCode?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  priceId?: InputMaybe<Scalars['String']>;
};

/** Platforms the app can run on (iOS and Android for now, web in the future...) */
export enum SupportedPlatforms {
  Android = 'ANDROID',
  Ios = 'IOS',
  Unknown = 'UNKNOWN'
}

/** Swap user's exercise input type */
export type SwapUserExerciseType = {
  alternativeExerciseId: Scalars['String'];
  circuitExerciseId: Scalars['String'];
  workoutId: Scalars['String'];
};

/** Multiple workout category assigned to workouts */
export type TargetedArea = {
  __typename?: 'TargetedArea';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  translations: TargetedAreaI18N;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter targetedareas */
export type TargetedAreaFiltersType = {
  /** Filter workout categories within an array of slugs */
  slugs?: InputMaybe<Array<Scalars['String']>>;
  /** Filter workout categories by title */
  title?: InputMaybe<Scalars['String']>;
};

/** Translated fields of targeted areas */
export type TargetedAreaI18N = {
  __typename?: 'TargetedAreaI18N';
  title: Scalars['String'];
};

/** Used to order targetedareas */
export type TargetedAreaOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<TargetedAreaOrderProperties>;
};

/** TargetedArea properties that can be ordered */
export enum TargetedAreaOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Used to fine tune user account merge */
export type TransferUserAccountCommandOptionsType = {
  /** Used to also merge subscriptions, default to false */
  withSubscriptions?: InputMaybe<Scalars['Boolean']>;
};

/** Info on user transfer data impact */
export type TransferUserAccountResponseType = {
  __typename?: 'TransferUserAccountResponseType';
  oldAccountArticleFavorites: Scalars['Float'];
  oldAccountCollages: Scalars['Float'];
  oldAccountExerciseHistory: Scalars['Float'];
  oldAccountProgramProgression: Scalars['Float'];
  oldAccountProgressPictures: Scalars['Float'];
  oldAccountRecipeFavorites: Scalars['Float'];
  oldAccountSetHistory: Scalars['Float'];
  oldAccountSubscriptions: Scalars['Float'];
  oldAccountWeekProgression: Scalars['Float'];
  oldAccountWorkoutFavorites: Scalars['Float'];
  oldAccountWorkoutHistory: Scalars['Float'];
};

/** The different kind of media */
export enum TypeOfMedia {
  Image = 'Image',
  Video = 'Video'
}

/** Unit systems (metric or imperial) */
export enum UnitSystems {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC'
}

export type UpdateArticleCategoriesDto = {
  categoryId: Scalars['String'];
};

/** Update a articleCategory */
export type UpdateArticleCategoryInput = {
  color?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateArticleCategoryTranslationsInput>>;
};

export type UpdateArticleDto = {
  articleGroups?: InputMaybe<Array<CreateArticleArticleGroupsDto>>;
  categories?: InputMaybe<Array<UpdateArticleCategoriesDto>>;
  focused?: InputMaybe<Scalars['Boolean']>;
  fullscreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  publicationStatus?: InputMaybe<PublicationStatus>;
  translations?: InputMaybe<Array<UpdateArticleTranslationsDto>>;
};

/** Update an articleGroup */
export type UpdateArticleGroupInput = {
  articleGroupArticles?: InputMaybe<Array<CreateArticleGroupArticlesDto>>;
  color1?: InputMaybe<Scalars['String']>;
  color2?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  translations: Array<CreateArticleGroupTranslationsInput>;
};

export type UpdateArticleTranslationsDto = {
  content?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCoachingClientTagsDto = {
  coachingClientId: Scalars['String'];
  set?: InputMaybe<Array<Scalars['String']>>;
  unset?: InputMaybe<Array<Scalars['String']>>;
};

/** Update a equipment */
export type UpdateEquipmentInput = {
  imageId?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateEquipmentTranslationsInput>>;
};

export type UpdateExerciseAlternativesGroupExercisesDto = {
  exerciseId: Scalars['String'];
};

/** Update a exerciseAlternativesGroup */
export type UpdateExerciseAlternativesGroupInput = {
  exercises?: InputMaybe<Array<UpdateExerciseAlternativesGroupExercisesDto>>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateExerciseEquipmentsDto = {
  equipmentId: Scalars['String'];
};

/** Update a exercise */
export type UpdateExerciseInput = {
  bodyweight?: InputMaybe<Scalars['Boolean']>;
  equipments?: InputMaybe<Array<UpdateExerciseEquipmentsDto>>;
  fullScreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  mainMuscleGroupId?: InputMaybe<Scalars['String']>;
  repDuration?: InputMaybe<Scalars['Float']>;
  secondaryMuscleGroupId?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateExerciseTranslationsInput>>;
};

export type UpdateIngredientCategoriesDto = {
  categoryId: Scalars['String'];
};

/** Update a ingredientCategory */
export type UpdateIngredientCategoryInput = {
  slug?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateIngredientCategoryTranslationsInput>>;
};

/** Update an ingredient */
export type UpdateIngredientInput = {
  categories?: InputMaybe<Array<UpdateIngredientCategoriesDto>>;
  imageId?: InputMaybe<Scalars['String']>;
  score: Scalars['Float'];
  translations: Array<UpdateIngredientTranslationsInput>;
  type?: InputMaybe<IngredientType>;
};

/** Update translations of an ingredient */
export type UpdateIngredientTranslationsInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateMeasurementHistoryInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  measurementHistoryId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Boolean']>;
  pictures?: InputMaybe<Array<UpdateMeasurementPictureInput>>;
  waistline?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdateMeasurementPictureInput = {
  /** Provide another value to update the image, or null to delete the current one */
  localDeviceTempFilePath?: InputMaybe<Scalars['String']>;
  /** Required when updating measurements. Omit it from the array altogether if you do not want to update the picture. */
  measurementPosition: Scalars['Float'];
};

/** Update a muscleGroup */
export type UpdateMuscleGroupInput = {
  slug?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateMuscleGroupTranslationsInput>>;
};

/** Update a objective */
export type UpdateObjectiveInput = {
  imageId?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateObjectiveTranslationsInput>>;
};

/** Update the equipments of a program */
export type UpdateProgramEquipments = {
  equipmentId: Scalars['String'];
};

/** Update a program */
export type UpdateProgramInput = {
  averageWorkoutDuration?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  equipments?: InputMaybe<Array<UpdateProgramEquipments>>;
  fullscreenImageId?: InputMaybe<Scalars['String']>;
  gym?: InputMaybe<Scalars['Boolean']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  home?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<ProgramLevel>;
  maxDays?: InputMaybe<Scalars['Float']>;
  maxDuration?: InputMaybe<Scalars['Float']>;
  minDays?: InputMaybe<Scalars['Float']>;
  minDuration?: InputMaybe<Scalars['Float']>;
  objectives?: InputMaybe<Array<UpdateProgramObjectivesDto>>;
  programVariationGroupId?: InputMaybe<Scalars['String']>;
  publicationStatus?: InputMaybe<PublicationStatus>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tags?: InputMaybe<Array<UpdateProgramTagsDto>>;
  targetedAreas?: InputMaybe<Array<UpdateProgramTargetedAreasDto>>;
  translations?: InputMaybe<Array<CreateProgramTranslationsInput>>;
  type?: InputMaybe<ProgramType>;
  weeks?: InputMaybe<Array<UpdateProgramWeeksDto>>;
};

/** Update the objectives of a program */
export type UpdateProgramObjectivesDto = {
  objectiveId: Scalars['String'];
};

/** Update a program progression */
export type UpdateProgramProgressionInput = {
  completionPercentage?: InputMaybe<Scalars['Float']>;
  currentProgramWeekId?: InputMaybe<Scalars['String']>;
  finished?: InputMaybe<Scalars['Boolean']>;
  finishedAt?: InputMaybe<Scalars['DateTime']>;
  programId: Scalars['String'];
  startedAt?: InputMaybe<Scalars['DateTime']>;
  weeksUnlocked?: InputMaybe<Scalars['Float']>;
};

/** Update a program group */
export type UpdateProgramTagInput = {
  internalName?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  slug?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateProgramTagTranslationsInput>>;
};

/** Update the tags of a program */
export type UpdateProgramTagsDto = {
  tagId: Scalars['String'];
};

/** Update the targeted areas of a program */
export type UpdateProgramTargetedAreasDto = {
  targetedAreaId: Scalars['String'];
};

/** Update a program group */
export type UpdateProgramVariationGroupInput = {
  internalName?: InputMaybe<Scalars['String']>;
};

/** Update a program week  */
export type UpdateProgramWeekDto = {
  id?: InputMaybe<Scalars['String']>;
  items: Array<UpdateProgramWeekItem>;
};

/** Update an item within a program week */
export type UpdateProgramWeekItem = {
  position: Scalars['Float'];
  restDayId?: InputMaybe<Scalars['String']>;
  workoutId?: InputMaybe<Scalars['String']>;
};

/** Create the association between a program and a programWeek */
export type UpdateProgramWeeksDto = {
  id?: InputMaybe<Scalars['String']>;
  position: Scalars['Float'];
  programWeek?: InputMaybe<UpdateProgramWeekDto>;
};

export type UpdateRecipeDto = {
  calories: Scalars['Float'];
  carbs: Scalars['Float'];
  cookTime: Scalars['Float'];
  fat: Scalars['Float'];
  fullscreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<Array<UpdateRecipeIngredientsDto>>;
  preparationTime: Scalars['Float'];
  proteins: Scalars['Float'];
  publicationStatus?: InputMaybe<PublicationStatus>;
  servings: Scalars['Float'];
  tags?: InputMaybe<Array<UpdateRecipeTagsDto>>;
  translations?: InputMaybe<Array<UpdateRecipeTranslationsDto>>;
};

export type UpdateRecipeIngredientsDto = {
  ingredientId: Scalars['String'];
  quantity: Scalars['Float'];
  volume: Scalars['Float'];
  weight: Scalars['Float'];
};

/** Update a recipeList */
export type UpdateRecipeListInput = {
  recipes?: InputMaybe<Array<RecipeListItemInput>>;
};

/** Update a recipeTag */
export type UpdateRecipeTagInput = {
  color?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateRecipeTagTranslationsInput>>;
};

export type UpdateRecipeTagsDto = {
  tagId: Scalars['String'];
};

export type UpdateRecipeTranslationsDto = {
  content?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRestDayDto = {
  fullScreenImageId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<UpdateRestDayTranslationsDto>>;
};

export type UpdateRestDayTranslationsDto = {
  internalName?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  quoteAuthor?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateSelfUserCredentialsDto = {
  password?: InputMaybe<Scalars['String']>;
  pinCode?: InputMaybe<Scalars['String']>;
};

export type UpdateSelfUserDto = {
  activeProgramId?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  credentials?: InputMaybe<UpdateSelfUserCredentialsDto>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<UpdateSelfUserNotificationsDto>;
  onboardingMatchingAnswers?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<SupportedPlatforms>;
  profilePictureId?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<UpdateSelfUserSettingsDto>;
};

export type UpdateSelfUserNotificationsDto = {
  oneSignalPlayerId?: InputMaybe<Scalars['String']>;
};

export type UpdateSelfUserSettingsDto = {
  locale?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<UserMetadataDto>;
  preventSleepMode?: InputMaybe<Scalars['Boolean']>;
  profilePictureUsageAllowed?: InputMaybe<Scalars['Boolean']>;
  unitSystem?: InputMaybe<UnitSystems>;
  workoutSounds?: InputMaybe<Scalars['Boolean']>;
};

/** Update a targetedArea */
export type UpdateTargetedAreaInput = {
  color?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateTargetedAreaTranslationsInput>>;
};

export type UpdateUserEmailDto = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};

export type UpdateUserEmailServiceResultType = {
  __typename?: 'UpdateUserEmailServiceResultType';
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateUserEmailType = {
  __typename?: 'UpdateUserEmailType';
  activecampaignResult: UpdateUserEmailServiceResultType;
  databaseResult: UpdateUserEmailServiceResultType;
  intercomResult: UpdateUserEmailServiceResultType;
  stripeResult: UpdateUserEmailServiceResultType;
};

export type UpdateUserSettingsDto = {
  profilePictureUsageAllowed?: InputMaybe<Scalars['Boolean']>;
};

/** Update a userSubscription */
export type UpdateUserSubscriptionInput = {
  endDate: Scalars['DateTime'];
  note?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

/** Update a week progression */
export type UpdateWeekProgressionInput = {
  finished?: InputMaybe<Scalars['Boolean']>;
  finishedAt?: InputMaybe<Scalars['DateTime']>;
  programWeekId: Scalars['String'];
  startedAt?: InputMaybe<Scalars['DateTime']>;
};

/** Update the categories of a workout */
export type UpdateWorkoutCategoriesDto = {
  workoutCategoryId: Scalars['String'];
};

/** Update a workoutCategory */
export type UpdateWorkoutCategoryInput = {
  color?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CreateWorkoutCategoryTranslationsInput>>;
};

/** Update the circuits of a workout (association between a circuit and a workout) */
export type UpdateWorkoutCircuitsDto = {
  circuit: CreateCircuitInput;
  position: Scalars['Float'];
};

/** Update a workout */
export type UpdateWorkoutInput = {
  actualDuration?: InputMaybe<Scalars['Float']>;
  authorId?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<UpdateWorkoutCategoriesDto>>;
  circuits?: InputMaybe<Array<UpdateWorkoutCircuitsDto>>;
  estimatedDuration?: InputMaybe<Scalars['Float']>;
  fullScreenImageId?: InputMaybe<Scalars['ID']>;
  genericWorkout?: InputMaybe<CreateGenericWorkoutDto>;
  imageId?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Float']>;
  numberOfTimesDone?: InputMaybe<Scalars['Float']>;
  publicationStatus?: InputMaybe<PublicationStatus>;
  recoveryWorkout?: InputMaybe<CreateRecoveryWorkoutDto>;
  targetedAreas?: InputMaybe<Array<UpdateWorkoutTargetedAreasDto>>;
  translations?: InputMaybe<Array<UpdateWorkoutTranslationsInput>>;
  type?: InputMaybe<WorkoutType>;
  warmupId?: InputMaybe<Scalars['String']>;
};

/** Update the targeted areas of a workout */
export type UpdateWorkoutTargetedAreasDto = {
  targetedAreaId: Scalars['String'];
};

/** Update translations of a workout */
export type UpdateWorkoutTranslationsInput = {
  bodyFocus?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  hashtags?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserCredentialsType = {
  __typename?: 'UserCredentialsType';
  intercomHashAndroid?: Maybe<Scalars['String']>;
  intercomHashIOS?: Maybe<Scalars['String']>;
  intercomHashWeb?: Maybe<Scalars['String']>;
  pinCode?: Maybe<Scalars['String']>;
  resetPasswordRequestDate?: Maybe<Scalars['DateTime']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

/** User event */
export type UserEvent = {
  __typename?: 'UserEvent';
  date: Scalars['DateTime'];
  headers?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  type: UserEvents;
  userId: Scalars['ID'];
};

/** Used to filter user events */
export type UserEventFiltersType = {
  /** Filter user events by user id */
  userId?: InputMaybe<Scalars['String']>;
};

/** Used to order user events */
export type UserEventOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<UserEventOrderProperties>;
};

/** UserEvent properties that can be ordered */
export enum UserEventOrderProperties {
  Date = 'date'
}

/** The different kinds of users events */
export enum UserEvents {
  ChangePasswordAttempt = 'ChangePasswordAttempt',
  ChangePasswordSuccess = 'ChangePasswordSuccess',
  CreateSubscription = 'CreateSubscription',
  LoginAttempt = 'LoginAttempt',
  LoginSuccess = 'LoginSuccess',
  ProgramProgression = 'ProgramProgression',
  Registration = 'Registration',
  RemoveCollageAttempt = 'RemoveCollageAttempt',
  RemoveCollageSuccess = 'RemoveCollageSuccess',
  RemovePinCodeAttempt = 'RemovePinCodeAttempt',
  RemovePinCodeSuccess = 'RemovePinCodeSuccess',
  RemoveProgressPictureAttempt = 'RemoveProgressPictureAttempt',
  RemoveProgressPictureSuccess = 'RemoveProgressPictureSuccess',
  ResetPasswordRequestAttempt = 'ResetPasswordRequestAttempt',
  ResetPasswordRequestSuccess = 'ResetPasswordRequestSuccess',
  ResetPinCodeRequestAttempt = 'ResetPinCodeRequestAttempt',
  ResetPinCodeRequestSuccess = 'ResetPinCodeRequestSuccess',
  StoreEvent = 'StoreEvent',
  StripeEvent = 'StripeEvent',
  UpdateSubscription = 'UpdateSubscription',
  UploadCollageAttempt = 'UploadCollageAttempt',
  UploadCollageSuccess = 'UploadCollageSuccess',
  UploadProfilePictureAttempt = 'UploadProfilePictureAttempt',
  UploadProfilePictureSuccess = 'UploadProfilePictureSuccess',
  UploadProgressPictureAttempt = 'UploadProgressPictureAttempt',
  UploadProgressPictureSuccess = 'UploadProgressPictureSuccess',
  UserUpdate = 'UserUpdate'
}

/** Used to filter users */
export type UserFiltersType = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  /** Users who authored a regular program */
  isRegularProgramAuthor?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  /** User has an android subscription with a latestReceipt matching this purchase token */
  playStorePurchaseToken?: InputMaybe<Scalars['String']>;
};

export type UserMetadataDto = {
  appsflyerId?: InputMaybe<Scalars['String']>;
};

export type UserMetadataType = {
  __typename?: 'UserMetadataType';
  appsflyerId?: Maybe<Scalars['String']>;
};

export type UserNotificationsType = {
  __typename?: 'UserNotificationsType';
  oneSignalPlayerId?: Maybe<Scalars['String']>;
};

/** Used to order users */
export type UserOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<UserOrderProperties>;
};

/** User properties that can be ordered */
export enum UserOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** User program association. This lets users access specific programs that are unlisted */
export type UserProgram = {
  __typename?: 'UserProgram';
  id: Scalars['ID'];
  programId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Available user roles */
export enum UserRoles {
  Admin = 'ADMIN',
  Coach = 'COACH',
  Influencer = 'INFLUENCER',
  Partner = 'PARTNER',
  User = 'USER'
}

export type UserSettingsType = {
  __typename?: 'UserSettingsType';
  id: Scalars['ID'];
  locale: Scalars['String'];
  metadata?: Maybe<UserMetadataType>;
  preventSleepMode?: Maybe<Scalars['Boolean']>;
  profilePictureUsageAllowed?: Maybe<Scalars['Boolean']>;
  unitSystem?: Maybe<UnitSystems>;
  user: UserType;
  workoutSounds?: Maybe<Scalars['Boolean']>;
};

/** User Statistics */
export type UserStats = {
  __typename?: 'UserStats';
  endDate?: Maybe<Scalars['DateTime']>;
  mainMuscleGroups?: Maybe<Array<MuscleGroup>>;
  numberOfCollages: Scalars['Float'];
  numberOfProgramsCompleted?: Maybe<Scalars['Float']>;
  numberOfProgressPictures: Scalars['Float'];
  numberOfWorkoutDone?: Maybe<Scalars['Float']>;
  secondaryMuscleGroups?: Maybe<Array<MuscleGroup>>;
  secondsSpentInWorkout?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

/** Workout userSubscription composed of weeks */
export type UserSubscription = {
  __typename?: 'UserSubscription';
  active?: Maybe<Scalars['Boolean']>;
  cancelled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  interval: UserSubscriptionInterval;
  latestReceipt: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
  productId: Scalars['String'];
  startDate: Scalars['DateTime'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  trialPeriod: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<UserType>;
};

/** Renewal interval for a given user subscription (annually, quarterly or monthly) */
export enum UserSubscriptionInterval {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Unknown = 'UNKNOWN'
}

/** Used to order userSubscriptions */
export type UserSubscriptionOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<UserSubscriptionOrderProperties>;
};

/** UserSubscription properties that can be ordered */
export enum UserSubscriptionOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** User swapped exercise type */
export type UserSwappedExercise = {
  __typename?: 'UserSwappedExercise';
  alternativeExerciseId: Scalars['String'];
  circuitExerciseId: Scalars['String'];
  id: Scalars['ID'];
  userId: Scalars['String'];
  workoutId: Scalars['String'];
};

export type UserType = {
  __typename?: 'UserType';
  activeCoachingProgram?: Maybe<Program>;
  activeCoachingProgramId?: Maybe<Scalars['String']>;
  activeProgram?: Maybe<Program>;
  activeProgramId: Scalars['String'];
  birthDate?: Maybe<Scalars['DateTime']>;
  coaches: Array<CoachType>;
  coachingClients: Array<CoachingClientType>;
  connectionMethods?: Maybe<Array<ConnectionMethods>>;
  createdAt: Scalars['DateTime'];
  credentials: UserCredentialsType;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  influencerPortrait?: Maybe<MediaType>;
  lastName?: Maybe<Scalars['String']>;
  notifications: UserNotificationsType;
  onboardingMatchingAnswers?: Maybe<Scalars['String']>;
  picture?: Maybe<MediaType>;
  platform?: Maybe<SupportedPlatforms>;
  primaryColor?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<UserUpload>;
  registrationSource: Scalars['String'];
  role: UserRoles;
  secondaryColor?: Maybe<Scalars['String']>;
  settings: UserSettingsType;
  updatedAt: Scalars['DateTime'];
  webFunnelAnalytics?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** User media upload (profile pic...) */
export type UserUpload = {
  __typename?: 'UserUpload';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  status: UserUploadStatuses;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

/**
 *
 *         * `PROCESSING` : file has just been uploaded and is being processed by the queue
 *         * `PROCESSED` : file has been properly processed by the queue (compressed, resized...)
 *         * `LOCAL_FILE_AVAILABLE` : optimistic UI upload is ongoing, the device has the file locally for now
 *         * `FAILED` : file couldn't be processed, its row will be removed soon
 *
 */
export enum UserUploadStatuses {
  Failed = 'FAILED',
  LocalFileAvailable = 'LOCAL_FILE_AVAILABLE',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING'
}

/** Validate an Apple store payment receipt */
export type ValidateAppleReceiptDto = {
  receipt: AppleReceiptPayloadInput;
};

/** Validate a google store payment receipt */
export type ValidateGoogleReceiptDto = {
  receipt: GoogleReceiptPayloadInput;
};

export type VimeoImportationResultType = {
  __typename?: 'VimeoImportationResultType';
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  success: Scalars['Boolean'];
};

export type WeekCompletionSummary = {
  __typename?: 'WeekCompletionSummary';
  numberOfPeopleWhoCompleted?: Maybe<Scalars['Float']>;
  peopleWhoCompletedRandomPictures?: Maybe<Array<UserUpload>>;
  programWeekId: Scalars['String'];
};

/** WeekProgression */
export type WeekProgression = {
  __typename?: 'WeekProgression';
  /** All completed workout for this week and program progression. Includes the same workout multiple times if it has been re-done. */
  completedWorkouts: Array<WorkoutHistory>;
  /** @deprecated Use finishedAt instead */
  finished: Scalars['Boolean'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  programProgression: ProgramProgression;
  programWeekId: Scalars['String'];
  startedAt: Scalars['DateTime'];
};

/** Workout */
export type Workout = {
  __typename?: 'Workout';
  actualDuration?: Maybe<Scalars['Float']>;
  author: UserType;
  categories?: Maybe<Array<WorkoutCategories>>;
  circuits?: Maybe<Array<WorkoutCircuits>>;
  createdAt: Scalars['DateTime'];
  equipments?: Maybe<Array<WorkoutEquipments>>;
  estimatedDuration?: Maybe<Scalars['Float']>;
  fullScreenImage?: Maybe<MediaType>;
  genericWorkout?: Maybe<GenericWorkout>;
  id: Scalars['ID'];
  image?: Maybe<MediaType>;
  isAttachedToProgram: Scalars['Boolean'];
  level?: Maybe<Scalars['Float']>;
  link?: Maybe<Scalars['String']>;
  /** Number of time the workout has been completed */
  numberOfTimesDone?: Maybe<Scalars['Float']>;
  /** Number of users who completed the workout */
  numberOfUsersWhoCompleted?: Maybe<Scalars['Float']>;
  peopleWhoCompletedRandomPictures?: Maybe<Array<UserUpload>>;
  publicationStatus?: Maybe<PublicationStatus>;
  recoveryWorkout?: Maybe<RecoveryWorkout>;
  targetedAreas?: Maybe<Array<WorkoutTargetedAreas>>;
  translations: WorkoutI18N;
  type?: Maybe<WorkoutType>;
  updatedAt: Scalars['DateTime'];
  videos?: Maybe<Array<WorkoutVideos>>;
  warmup?: Maybe<Workout>;
};

export type WorkoutCategories = {
  __typename?: 'WorkoutCategories';
  category: WorkoutCategory;
  id: Scalars['ID'];
};

/** Multiple workout category assigned to workouts */
export type WorkoutCategory = {
  __typename?: 'WorkoutCategory';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  /** @deprecated Removed from columns in 1.6.0 */
  targetedArea?: Maybe<Scalars['Boolean']>;
  translations: WorkoutCategoryI18N;
  updatedAt: Scalars['DateTime'];
};

/** Used to filter workoutCategories */
export type WorkoutCategoryFiltersType = {
  /** Filter workout categories within an array of slugs */
  slugs?: InputMaybe<Array<Scalars['String']>>;
  /** Filter workout categories by title */
  title?: InputMaybe<Scalars['String']>;
};

/** Translated fields of workoutCategories */
export type WorkoutCategoryI18N = {
  __typename?: 'WorkoutCategoryI18N';
  title: Scalars['String'];
};

/** Used to order workoutCategories */
export type WorkoutCategoryOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<WorkoutCategoryOrderProperties>;
};

/** WorkoutCategory properties that can be ordered */
export enum WorkoutCategoryOrderProperties {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

/** Workout circuits */
export type WorkoutCircuits = {
  __typename?: 'WorkoutCircuits';
  circuit: Circuit;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  position: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  workout: Workout;
};

export type WorkoutEquipments = {
  __typename?: 'WorkoutEquipments';
  equipment: Equipment;
  /** @deprecated Related table has been deleted */
  id?: Maybe<Scalars['ID']>;
};

/** Workout favorite */
export type WorkoutFavorite = {
  __typename?: 'WorkoutFavorite';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
  workout: Workout;
  workoutId: Scalars['ID'];
};

/** Used to order workout favorites */
export type WorkoutFavoriteOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<WorkoutFavoriteOrderProperties>;
};

/** Workout Favorite properties that can be ordered */
export enum WorkoutFavoriteOrderProperties {
  CreatedAt = 'createdAt'
}

/** Used to filter workouts */
export type WorkoutFiltersType = {
  /** Filter workout by maximum duration (in minutes, only applies to the _actual_ duration, not the estimated one) */
  actualMaxDuration?: InputMaybe<Scalars['Float']>;
  /** Filter workout by minimum duration (in minutes, only applies to the _actual_ duration, not the estimated one) */
  actualMinDuration?: InputMaybe<Scalars['Float']>;
  /** Filter workout by author id */
  authorId?: InputMaybe<Array<Scalars['String']>>;
  /** Filter workout by the slug of one of its categories (stretching, warm-up...) */
  categorySlug?: InputMaybe<Array<Scalars['String']>>;
  /** Exclude workout by the slug of one of its categories (full-body, legs...) */
  categorySlugExcluded?: InputMaybe<Array<Scalars['String']>>;
  /** Filter workout by the id of one of its equipments */
  equipmentIds?: InputMaybe<Array<Scalars['String']>>;
  /** Exclude workouts that are part of a program */
  excludeProgramItems?: InputMaybe<Scalars['Boolean']>;
  /** Filter workout by their location (home if true, else gym) */
  home?: InputMaybe<Scalars['Boolean']>;
  /** Only include matching workouts */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Filter workout by internalName */
  internalName?: InputMaybe<Scalars['String']>;
  /** Filter workout by level (eq) */
  level?: InputMaybe<Array<Scalars['Float']>>;
  /** Filter workout by maximum duration (in minutes, only applies to the _estimated_ duration, not the actual one) */
  maxDuration?: InputMaybe<Scalars['Float']>;
  /** Filter workout by minimum duration (in minutes, only applies to the _estimated_ duration, not the actual one) */
  minDuration?: InputMaybe<Scalars['Float']>;
  /** Filter workout by publication status */
  publicationStatus?: InputMaybe<PublicationStatus>;
  /** Filter workout by the slug of one of its targeted areas (full-body, legs...) */
  targetedAreaSlug?: InputMaybe<Array<Scalars['String']>>;
  /** Filter workout by title */
  title?: InputMaybe<Scalars['String']>;
  /** Filter workout by workout type */
  type?: InputMaybe<WorkoutType>;
  /** Filter workout with no equipments associated */
  withoutEquipments?: InputMaybe<Scalars['Boolean']>;
};

/** WorkoutHistory */
export type WorkoutHistory = {
  __typename?: 'WorkoutHistory';
  createdAt: Scalars['DateTime'];
  duration: Scalars['Float'];
  exerciseHistories: Array<ExerciseHistory>;
  id: Scalars['ID'];
  position: Scalars['Float'];
  restDayId?: Maybe<Scalars['String']>;
  totalReps: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: UserType;
  userId: Scalars['String'];
  weekProgression?: Maybe<WeekProgression>;
  weekProgressionId?: Maybe<Scalars['String']>;
  workout?: Maybe<Workout>;
  workoutId?: Maybe<Scalars['String']>;
};

/** Used to filter workoutHistories */
export type WorkoutHistoryFiltersType = {
  /** Filter workoutHistory by workouts being completed as part of a program */
  fromProgram?: InputMaybe<Scalars['Boolean']>;
  /** Return only workout history saved before given date */
  maxDate?: InputMaybe<Scalars['DateTime']>;
  /** Return only workout history saved after given date (or equal) */
  minDate?: InputMaybe<Scalars['DateTime']>;
  /** Filter workoutHistory by workouts that are not part of a program and do not have a non-targeted area category associated to them (quick workouts or such). */
  miscellaneous?: InputMaybe<Scalars['Boolean']>;
  /** Filter workoutHistory by circuitExercise type */
  type?: InputMaybe<CircuitExerciseType>;
  /** Filter workoutHistory by workouts having a specific tag (and possibly more) */
  workoutCategorySlug?: InputMaybe<Scalars['String']>;
  /** Filter workoutHistory with a list of workoutId */
  workoutIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Used to order programs */
export type WorkoutHistoryOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<WorkoutHistoryOrderProperties>;
};

/** WorkoutHistory properties that can be ordered */
export enum WorkoutHistoryOrderProperties {
  CreatedAt = 'createdAt',
  Position = 'position',
  UpdatedAt = 'updatedAt'
}

/** Translated fields of workouts */
export type WorkoutI18N = {
  __typename?: 'WorkoutI18N';
  bodyFocus?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hashtags?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** WorkoutList */
export type WorkoutList = {
  __typename?: 'WorkoutList';
  category: WorkoutCategory;
  createdAt: Scalars['DateTime'];
  currentWorkout?: Maybe<Workout>;
  currentWorkoutId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  items: Array<WorkoutListItems>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workoutCategoryId: Scalars['ID'];
};

/** Items of workout list */
export type WorkoutListItems = {
  __typename?: 'WorkoutListItems';
  id: Scalars['ID'];
  position: Scalars['Float'];
  workout: Workout;
  workoutId: Scalars['ID'];
  workoutListId: Scalars['ID'];
};

/** Used to order workouts */
export type WorkoutOrderByType = {
  /** The orderBy */
  order: OrderByEnum;
  /** The property which should be ordered */
  property?: InputMaybe<WorkoutOrderProperties>;
};

/** Workout properties that can be ordered */
export enum WorkoutOrderProperties {
  ActualDuration = 'actualDuration',
  CreatedAt = 'createdAt',
  EstimatedDuration = 'estimatedDuration',
  NumberOfTimesDone = 'numberOfTimesDone',
  UpdatedAt = 'updatedAt'
}

export type WorkoutTargetedAreas = {
  __typename?: 'WorkoutTargetedAreas';
  id: Scalars['ID'];
  targetedArea: TargetedArea;
};

/** The different types of workout */
export enum WorkoutType {
  Generic = 'GENERIC',
  Recovery = 'RECOVERY',
  Video = 'VIDEO'
}

/** Videos of workout */
export type WorkoutVideos = {
  __typename?: 'WorkoutVideos';
  id: Scalars['ID'];
  video?: Maybe<MediaType>;
};

export type GetArticlesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ArticleOrderByType> | ArticleOrderByType>;
}>;


export type GetArticlesQuery = { __typename?: 'Query', articles: { __typename?: 'FindArticleResultType', total: number, offset: number, data: Array<{ __typename?: 'Article', id: string, focused: boolean, slug?: string | null | undefined, createdAt: any, updatedAt: any, author: { __typename?: 'UserType', lastName?: string | null | undefined, firstName?: string | null | undefined }, categories?: Array<{ __typename?: 'ArticleCategories', category: { __typename?: 'ArticleCategory', color: string, translations: { __typename?: 'ArticleCategoryI18N', title: string } } }> | null | undefined, translations: { __typename?: 'ArticleI18N', title: string, content: string }, image?: { __typename?: 'MediaType', url?: string | null | undefined, thumbnailUrl: string } | null | undefined, fullScreenImage?: { __typename?: 'MediaType', url?: string | null | undefined, thumbnailUrl: string } | null | undefined, videos?: Array<{ __typename?: 'ArticleVideos', video?: { __typename?: 'MediaType', url?: string | null | undefined, thumbnailUrl: string } | null | undefined }> | null | undefined }> } };

export type LoginWithEmailMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginWithEmailMutation = { __typename?: 'Mutation', login: { __typename?: 'LoggedInType', accessToken: string, refreshToken: string } };

export type LoginWithRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type LoginWithRefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'LoggedInType', accessToken: string, refreshToken: string } };

export type GetAllEquipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEquipmentsQuery = { __typename?: 'Query', equipments: { __typename?: 'FindEquipmentResultType', data: Array<{ __typename?: 'Equipment', id: string, translations: { __typename?: 'EquipmentI18N', name: string } }> } };

export type GetInfluencersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInfluencersQuery = { __typename?: 'Query', influencers: { __typename?: 'FindUserResultType', total: number, data: Array<{ __typename?: 'UserType', id: string, firstName?: string | null | undefined, picture?: { __typename?: 'MediaType', url?: string | null | undefined } | null | undefined }> } };

export type GetProgramMissingFieldsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProgramMissingFieldsQuery = { __typename?: 'Query', program: { __typename?: 'Program', id: string, type?: ProgramType | null | undefined, averageWorkoutDuration?: number | null | undefined, targetedAreas?: Array<{ __typename?: 'WorkoutTargetedAreas', targetedArea: { __typename?: 'TargetedArea', id: string, translations: { __typename?: 'TargetedAreaI18N', title: string } } }> | null | undefined } };

export type UpdateProgramMutationVariables = Exact<{
  programId: Scalars['String'];
  updateProgramDto: UpdateProgramInput;
}>;


export type UpdateProgramMutation = { __typename?: 'Mutation', updateProgram: { __typename?: 'Program', id: string } };

export type GetTargetedAreasQueryVariables = Exact<{
  slugs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetTargetedAreasQuery = { __typename?: 'Query', targetedAreas: { __typename?: 'FindTargetedAreaResultType', total: number, data: Array<{ __typename?: 'TargetedArea', id: string, slug: string, color: string, translations: { __typename?: 'TargetedAreaI18N', title: string } }> } };

export type GetAllTargetedAreasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTargetedAreasQuery = { __typename?: 'Query', targetedAreas: { __typename?: 'FindTargetedAreaResultType', data: Array<{ __typename?: 'TargetedArea', id: string, translations: { __typename?: 'TargetedAreaI18N', title: string } }> } };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'UserType', id: string, credentials: { __typename?: 'UserCredentialsType', resetPasswordToken?: string | null | undefined, resetPasswordRequestDate?: any | null | undefined } } };

export type CreateWorkoutMutationVariables = Exact<{
  input: CreateWorkoutInput;
}>;


export type CreateWorkoutMutation = { __typename?: 'Mutation', createWorkout: { __typename?: 'Workout', id: string } };

export type UpdateWorkoutMutationVariables = Exact<{
  workoutId: Scalars['String'];
  input: UpdateWorkoutInput;
}>;


export type UpdateWorkoutMutation = { __typename?: 'Mutation', updateWorkout: { __typename?: 'Workout', id: string } };

export type MissingWorkoutFieldsQueryVariables = Exact<{
  workoutId: Scalars['String'];
}>;


export type MissingWorkoutFieldsQuery = { __typename?: 'Query', workout: { __typename?: 'Workout', equipments?: Array<{ __typename?: 'WorkoutEquipments', equipment: { __typename?: 'Equipment', id: string, translations: { __typename?: 'EquipmentI18N', name: string } } }> | null | undefined, targetedAreas?: Array<{ __typename?: 'WorkoutTargetedAreas', id: string, targetedArea: { __typename?: 'TargetedArea', id: string, slug: string, color: string, translations: { __typename?: 'TargetedAreaI18N', title: string } } }> | null | undefined } };

export type WorkoutsAutocompleteQueryVariables = Exact<{
  findWorkoutType: FindWorkout;
}>;


export type WorkoutsAutocompleteQuery = { __typename?: 'Query', workouts: { __typename?: 'FindWorkoutResultType', data: Array<{ __typename?: 'Workout', id: string, translations: { __typename?: 'WorkoutI18N', title: string, internalName?: string | null | undefined }, image?: { __typename?: 'MediaType', thumbnailUrl: string } | null | undefined }> } };


export const GetArticlesDocument = `
    query GetArticles($limit: Int, $offset: Int, $order: [ArticleOrderByType!]) {
  articles(findArticleType: {limit: $limit, offset: $offset, order: $order}) {
    total
    offset
    data {
      id
      author {
        lastName
        firstName
      }
      categories {
        category {
          color
          translations {
            title
          }
        }
      }
      translations {
        title
        content
      }
      focused
      slug
      image {
        url
        thumbnailUrl
      }
      fullScreenImage {
        url
        thumbnailUrl
      }
      videos {
        video {
          url
          thumbnailUrl
        }
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export const useGetArticlesQuery = <
      TData = GetArticlesQuery,
      TError = unknown
    >(
      variables?: GetArticlesQueryVariables,
      options?: UseQueryOptions<GetArticlesQuery, TError, TData>
    ) =>
    useQuery<GetArticlesQuery, TError, TData>(
      variables === undefined ? ['GetArticles'] : ['GetArticles', variables],
      fetcher<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, variables),
      options
    );
export const LoginWithEmailDocument = `
    mutation LoginWithEmail($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    accessToken
    refreshToken
  }
}
    `;
export const useLoginWithEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginWithEmailMutation, TError, LoginWithEmailMutationVariables, TContext>) =>
    useMutation<LoginWithEmailMutation, TError, LoginWithEmailMutationVariables, TContext>(
      'LoginWithEmail',
      (variables?: LoginWithEmailMutationVariables) => fetcher<LoginWithEmailMutation, LoginWithEmailMutationVariables>(LoginWithEmailDocument, variables)(),
      options
    );
export const LoginWithRefreshTokenDocument = `
    mutation LoginWithRefreshToken($refreshToken: String!) {
  refreshToken(input: {refreshToken: $refreshToken}) {
    accessToken
    refreshToken
  }
}
    `;
export const useLoginWithRefreshTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginWithRefreshTokenMutation, TError, LoginWithRefreshTokenMutationVariables, TContext>) =>
    useMutation<LoginWithRefreshTokenMutation, TError, LoginWithRefreshTokenMutationVariables, TContext>(
      'LoginWithRefreshToken',
      (variables?: LoginWithRefreshTokenMutationVariables) => fetcher<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables>(LoginWithRefreshTokenDocument, variables)(),
      options
    );
export const GetAllEquipmentsDocument = `
    query GetAllEquipments {
  equipments(findEquipmentType: {offset: 0, limit: 1000}) {
    data {
      id
      translations {
        name
      }
    }
  }
}
    `;
export const useGetAllEquipmentsQuery = <
      TData = GetAllEquipmentsQuery,
      TError = unknown
    >(
      variables?: GetAllEquipmentsQueryVariables,
      options?: UseQueryOptions<GetAllEquipmentsQuery, TError, TData>
    ) =>
    useQuery<GetAllEquipmentsQuery, TError, TData>(
      variables === undefined ? ['GetAllEquipments'] : ['GetAllEquipments', variables],
      fetcher<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>(GetAllEquipmentsDocument, variables),
      options
    );
export const GetInfluencersDocument = `
    query GetInfluencers {
  influencers(findUserType: {limit: 100}) {
    total
    data {
      id
      firstName
      picture {
        url
      }
    }
  }
}
    `;
export const useGetInfluencersQuery = <
      TData = GetInfluencersQuery,
      TError = unknown
    >(
      variables?: GetInfluencersQueryVariables,
      options?: UseQueryOptions<GetInfluencersQuery, TError, TData>
    ) =>
    useQuery<GetInfluencersQuery, TError, TData>(
      variables === undefined ? ['GetInfluencers'] : ['GetInfluencers', variables],
      fetcher<GetInfluencersQuery, GetInfluencersQueryVariables>(GetInfluencersDocument, variables),
      options
    );
export const GetProgramMissingFieldsDocument = `
    query GetProgramMissingFields($id: String!) {
  program(id: $id) {
    id
    type
    averageWorkoutDuration
    targetedAreas {
      targetedArea {
        id
        translations {
          title
        }
      }
    }
  }
}
    `;
export const useGetProgramMissingFieldsQuery = <
      TData = GetProgramMissingFieldsQuery,
      TError = unknown
    >(
      variables: GetProgramMissingFieldsQueryVariables,
      options?: UseQueryOptions<GetProgramMissingFieldsQuery, TError, TData>
    ) =>
    useQuery<GetProgramMissingFieldsQuery, TError, TData>(
      ['GetProgramMissingFields', variables],
      fetcher<GetProgramMissingFieldsQuery, GetProgramMissingFieldsQueryVariables>(GetProgramMissingFieldsDocument, variables),
      options
    );
export const UpdateProgramDocument = `
    mutation UpdateProgram($programId: String!, $updateProgramDto: UpdateProgramInput!) {
  updateProgram(programId: $programId, updateProgramInput: $updateProgramDto) {
    id
  }
}
    `;
export const useUpdateProgramMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProgramMutation, TError, UpdateProgramMutationVariables, TContext>) =>
    useMutation<UpdateProgramMutation, TError, UpdateProgramMutationVariables, TContext>(
      'UpdateProgram',
      (variables?: UpdateProgramMutationVariables) => fetcher<UpdateProgramMutation, UpdateProgramMutationVariables>(UpdateProgramDocument, variables)(),
      options
    );
export const GetTargetedAreasDocument = `
    query GetTargetedAreas($slugs: [String!]) {
  targetedAreas(findTargetedAreaType: {limit: 100, filters: {slugs: $slugs}}) {
    total
    data {
      id
      translations {
        title
      }
      slug
      id
      color
    }
  }
}
    `;
export const useGetTargetedAreasQuery = <
      TData = GetTargetedAreasQuery,
      TError = unknown
    >(
      variables?: GetTargetedAreasQueryVariables,
      options?: UseQueryOptions<GetTargetedAreasQuery, TError, TData>
    ) =>
    useQuery<GetTargetedAreasQuery, TError, TData>(
      variables === undefined ? ['GetTargetedAreas'] : ['GetTargetedAreas', variables],
      fetcher<GetTargetedAreasQuery, GetTargetedAreasQueryVariables>(GetTargetedAreasDocument, variables),
      options
    );
export const GetAllTargetedAreasDocument = `
    query GetAllTargetedAreas {
  targetedAreas(findTargetedAreaType: {limit: 1000}) {
    data {
      id
      translations {
        title
      }
    }
  }
}
    `;
export const useGetAllTargetedAreasQuery = <
      TData = GetAllTargetedAreasQuery,
      TError = unknown
    >(
      variables?: GetAllTargetedAreasQueryVariables,
      options?: UseQueryOptions<GetAllTargetedAreasQuery, TError, TData>
    ) =>
    useQuery<GetAllTargetedAreasQuery, TError, TData>(
      variables === undefined ? ['GetAllTargetedAreas'] : ['GetAllTargetedAreas', variables],
      fetcher<GetAllTargetedAreasQuery, GetAllTargetedAreasQueryVariables>(GetAllTargetedAreasDocument, variables),
      options
    );
export const GetUserDocument = `
    query GetUser($userId: String!) {
  user(id: $userId) {
    id
    credentials {
      resetPasswordToken
      resetPasswordRequestDate
    }
  }
}
    `;
export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      variables: GetUserQueryVariables,
      options?: UseQueryOptions<GetUserQuery, TError, TData>
    ) =>
    useQuery<GetUserQuery, TError, TData>(
      ['GetUser', variables],
      fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
      options
    );
export const CreateWorkoutDocument = `
    mutation CreateWorkout($input: CreateWorkoutInput!) {
  createWorkout(input: $input) {
    id
  }
}
    `;
export const useCreateWorkoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateWorkoutMutation, TError, CreateWorkoutMutationVariables, TContext>) =>
    useMutation<CreateWorkoutMutation, TError, CreateWorkoutMutationVariables, TContext>(
      'CreateWorkout',
      (variables?: CreateWorkoutMutationVariables) => fetcher<CreateWorkoutMutation, CreateWorkoutMutationVariables>(CreateWorkoutDocument, variables)(),
      options
    );
export const UpdateWorkoutDocument = `
    mutation UpdateWorkout($workoutId: String!, $input: UpdateWorkoutInput!) {
  updateWorkout(id: $workoutId, input: $input) {
    id
  }
}
    `;
export const useUpdateWorkoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateWorkoutMutation, TError, UpdateWorkoutMutationVariables, TContext>) =>
    useMutation<UpdateWorkoutMutation, TError, UpdateWorkoutMutationVariables, TContext>(
      'UpdateWorkout',
      (variables?: UpdateWorkoutMutationVariables) => fetcher<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>(UpdateWorkoutDocument, variables)(),
      options
    );
export const MissingWorkoutFieldsDocument = `
    query MissingWorkoutFields($workoutId: String!) {
  workout(id: $workoutId) {
    equipments {
      equipment {
        id
        translations {
          name
        }
      }
    }
    targetedAreas {
      id
      targetedArea {
        id
        slug
        color
        translations {
          title
        }
      }
    }
  }
}
    `;
export const useMissingWorkoutFieldsQuery = <
      TData = MissingWorkoutFieldsQuery,
      TError = unknown
    >(
      variables: MissingWorkoutFieldsQueryVariables,
      options?: UseQueryOptions<MissingWorkoutFieldsQuery, TError, TData>
    ) =>
    useQuery<MissingWorkoutFieldsQuery, TError, TData>(
      ['MissingWorkoutFields', variables],
      fetcher<MissingWorkoutFieldsQuery, MissingWorkoutFieldsQueryVariables>(MissingWorkoutFieldsDocument, variables),
      options
    );
export const WorkoutsAutocompleteDocument = `
    query WorkoutsAutocomplete($findWorkoutType: FindWorkout!) {
  workouts(findWorkoutType: $findWorkoutType) {
    data {
      id
      translations {
        title
        internalName
      }
      image {
        thumbnailUrl
      }
    }
  }
}
    `;
export const useWorkoutsAutocompleteQuery = <
      TData = WorkoutsAutocompleteQuery,
      TError = unknown
    >(
      variables: WorkoutsAutocompleteQueryVariables,
      options?: UseQueryOptions<WorkoutsAutocompleteQuery, TError, TData>
    ) =>
    useQuery<WorkoutsAutocompleteQuery, TError, TData>(
      ['WorkoutsAutocomplete', variables],
      fetcher<WorkoutsAutocompleteQuery, WorkoutsAutocompleteQueryVariables>(WorkoutsAutocompleteDocument, variables),
      options
    );