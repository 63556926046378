export default class GraphQLErrorResponse extends Error {
  public data: GraphQLError[];

  constructor(payload: GraphQLError[]) {
    super(
      payload
        .map(error => {
          const {
            extensions: { code, exception },
            message
          } = error;
          return (
            `${code || ''} ${exception?.name || ''}` +
            `${exception?.status ? `(${exception.status})` : ''}` +
            `${message || ''}
                   `
          );
        })
        .filter(error => error !== null)
        .join(', ')
    );
    this.data = payload;
  }

  public matchesHTTPStatus(status: number): boolean {
    return this.data.some((error: GraphQLError) => error?.extensions?.exception?.status === status);
  }
}

export interface Location {
  line: number;
  column: number;
}

export interface Exception {
  response: string;
  status: number;
  message: string;
  name: string;
  stacktrace: string[];
}

export interface Extensions {
  code: string;
  exception: Exception;
}

export interface GraphQLError {
  message: string;
  locations: Location[];
  path: string[];
  extensions: Extensions;
}
